import {SET_SCHEDULES} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_SCHEDULES:
            return action.schedules
        default:
            return state;
    }
};
