import React, {Component} from 'react';
import './App.css';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import Home from "./Screen/Home";
import Iming from "./Page/Iming";
import KomaList from "./Page/KomaList";
import refreshToken from './config/refreshToken'

class App extends Component {

    async componentDidMount() {
        await refreshToken();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path={'/'} component={Home}/>
                    <Route exact path={'/iming'} component={Iming}/>
                    <Route exact path={'/reservation'} component={KomaList}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
