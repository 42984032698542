import React, {Component} from 'react';
import {Grid, Paper, withStyles} from '@material-ui/core';
import Calendar from "../Calendar/Calendar";
import TimeFrame from "../TimeFrame/TimeFrame";
import SeviceName from "../SeviceName/SeviceName";
import MediaBox from "../MediaBox/MediaBox"

const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit + 'px !important',
        paddingBottom: theme.spacing.unit + 'px !important',
    },
    paper: {
        padding: theme.spacing.unit * 1,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        border: 3 + 'px solid',
        borderRadius: 15,
    },
    timeFrame: {
        [theme.breakpoints.down('md')]:{
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]:{
            fontSize: '0.75rem',
        },
    },
    calendar: {
        border: 1 + 'px solid',
        borderColor: '#bfbfbf',
        backgroundColor: '#fff',
    },
    day: {
        [theme.breakpoints.down('md')]: {
            fontSize: '1.125rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9075rem',
        },
    },
    timeCss: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.6875rem',
        },
    }
})

class Event extends Component {

    render() {
        const {
            classes,
            title,
            img,
            month,
            day,
            serviceName,
            hourStart,
            hourEnd
        } = this.props;
        return (
            <Grid item xs={12} className={classes.root}>
                <Paper className={classes.paper}>
                    <MediaBox
                        title={title}
                        img={img}
                    />
                    <Grid container item style={{marginTop: 3}}>
                        <Grid item xs={5}>
                            <Calendar
                                month={month}
                                day={day}
                                classCalendar={classes.calendar}
                                classDay={classes.day}
                            />
                        </Grid>
                        <Grid item xs={7} style={{padding: '0px 5px',}}>
                            <SeviceName
                                serviceName={serviceName}
                            />
                            <TimeFrame
                                hourStart={hourStart}
                                hourEnd={hourEnd}
                                classTimeFrame={classes.timeFrame}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

export default withStyles(styles)(Event);