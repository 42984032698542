import * as TypeS from './../constants/actionTypes';

var initialState = [];

const ScheduleRS = (state = initialState, action) => {
    switch (action.type) {
        case TypeS.FETCH_SCHEDULES:
            // action.schedule.forEach((schedule, index) => {
            //     state.push({title: schedule.title, start: schedule.start})
            // });
            let result = {title: action.schedule.title, start: action.schedule.start, end: action.schedule.end, service_name: action.schedule.service_name};
            state.push(result);
            return [...state];
        default:
            return [...state];
    }
}

export default ScheduleRS