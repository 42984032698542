import _ from 'lodash'

export default   (state =[],action) =>{
    switch (action.type) {
        case 'SET_KOMA':
            return action.koma
        case "REMOVE_KOMA":
            _.remove(state, (s) => {
                return s.uuid === action.uuid
            })
            return [...state]
        case "UPDATE_CANCEL":
            let index = _.findIndex(state,{uuid:action.uuid})
            let newstate = [...state]
            if (index !== -1){
                newstate[index].is_deleted = 1
                return newstate
            }
            return newstate
        default:
            return state
    }
}