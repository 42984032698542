import React, {Component} from 'react';
import {Grid, withStyles} from '@material-ui/core';
import {FiberManualRecord, Group} from "@material-ui/icons";
import Topic from "../Topic/Topic";

const styles = theme => ({
    topic: {
        paddingBottom: '0px !important',
        paddingTop: '0px !important',
    },
    linkTo: {
        ...theme.typography.subheading,
        fontWeight: 'normal',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
        },
    }
})

class CommunityApp extends Component {
    render() {
        const {classes} = this.props
        return (
            <Grid item>
                <Grid container spacing={8}>
                    <Grid item xs={12} style={{marginBottom: '4px'}}>
                        <Topic icon={<Group/>} title={"コミュニティ"}/>
                    </Grid>
                    <Topic icon={<FiberManualRecord/>} title={"インサイトマップ"} linkTo={'#'} classLink={classes.linkTo}
                           classTopic={classes.topic}/>
                    <Topic icon={<FiberManualRecord/>} title={"ユニゾンリスニング"} linkTo={'#'} classLink={classes.linkTo}
                           classTopic={classes.topic}/>
                    <Topic icon={<FiberManualRecord/>} title={"エネカラー"} linkTo={'#'} classLink={classes.linkTo}
                           classTopic={classes.topic}/>
                    <Topic icon={<FiberManualRecord/>} title={"Inner Tours"} linkTo={'#'} classLink={classes.linkTo}
                           classTopic={classes.topic}/>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CommunityApp)