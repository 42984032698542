import React, {Component} from "react";
import KomaDescription from "../components/KomaDescription/KomaDescription";
import {CardMedia, Grid, withStyles} from "@material-ui/core";
import {KeyboardArrowRightOutlined} from "@material-ui/icons";
import Link from "react-router-dom/es/Link";
import MenuAppBar from "../components/Application/MenuAppBar/MenuAppBar";
import Footer from "../components/Application/Footer/Footer";
import {isMobileOnly} from "react-device-detect";

const styles = {
    title: {
        fontSize: '1.625rem',
        fontWeight: 'bold',
    },
    linkMain: {
        color: '#50a5e0',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        height: '1.375em',
        width: '1.375em',
    }
}

class Iming extends Component {
    render() {
        const {
            classes,
            titleMain = 'アイミング',
            ContentMain = 'アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。アイミングの説明。',
            linkMain = '/',
            imgMain = process.env.PUBLIC_URL + '/img/Iming.png',
        } = this.props
        let size = isMobileOnly ? 320 : 767
        return (
            <div>
                <MenuAppBar/>
                <Grid container justify={'center'} style={{minWidth: size}}>
                    <Grid container item xs={12} md={9} lg={8} justify={'center'}
                          style={{marginBottom: 42, marginTop: 100}} spacing={8}>
                        <Grid item xs={12} className={classes.title}>
                            {titleMain}
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                            <Grid item xs={12}>
                                {ContentMain}
                            </Grid>
                            <Grid container item xs={12} justify={'flex-end'} className={classes.linkMain}>
                                <KeyboardArrowRightOutlined className={classes.icon}/>
                                <Link to={linkMain} style={{color: '#50a5e0'}}>
                                    もっと詳しく
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid item xs={false} sm={1}>
                        </Grid>
                        <Grid container item xs={12} sm={5} justify={'flex-end'}>
                            <CardMedia
                                component="img"
                                alt={"title"}
                                image={imgMain}
                                title={"title"}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={9} lg={8} justify={'center'} spacing={8}>
                        <KomaDescription
                            NumberKey={1}
                            title={'陰の根源自己の存在意義'}
                            img={''}
                            NumberOn={1}
                            OnTo={1}
                            Coma={2}
                            Minute={120}
                            content={'解説あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ'}
                            linkAddApp
                            serviceName={'enecolor'}
                            calendarTitle={'予約済み'}
                            month={8}
                            day={7}
                            hourStart={'17:00'}
                            hourEnd={'19:45'}
                        />
                        <KomaDescription
                            NumberKey={2}
                            title={'陰の根源自己の存在意義'}
                            img={''}
                            NumberOn={1}
                            OnTo={5}
                            Coma={1}
                            Minute={60}
                            content={'解説あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ' +
                            '解説あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ'}
                            linkAddApp
                            serviceName={'innertours'}
                            calendarTitle={'完了'}
                            month={8}
                            day={12}
                            hourStart={'15:00'}
                            hourEnd={'16:40'}
                        />
                        <KomaDescription
                            NumberKey={3}
                            // title={'陰の根源自己の存在意義'}
                            // img={''}
                            // NumberOn={1}
                            // OnTo={1}
                            // Coma={2}
                            // Minute={120}
                            // content={'解説あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ'}
                            // linkAddApp
                            serviceName={'innertours'}
                            // calendarTitle={''}
                            // month={0}
                            // day={0}
                            // hourStart={'0:00'}
                            // hourEnd={'0:00'}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </div>
        )
    }
}

export default withStyles(styles)(Iming)