import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {Folder} from "@material-ui/icons";
import Topic from "../Topic/Topic";

class profileApp extends Component {
    render() {
        return (
            <Grid item>
                <Grid container spacing={8}>
                    <Topic icon={<Folder/>} title={'プログラム'}/>
                </Grid>
            </Grid>
        );
    }
}

export default profileApp