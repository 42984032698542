import {CONTENT} from "../constants/actionTypes";

export default (state=[''],action)=>{
    switch (action.type) {

        case CONTENT:
            let arr = [...state]
            arr[action.id] = action.content
            return arr
        default:
            return state;
    }

}