import React, {Component} from 'react'
import {Grid, withStyles} from '@material-ui/core'
import Avatar from "@material-ui/core/Avatar/Avatar"
import Tooltip from '@material-ui/core/Tooltip';
import {isMobileOnly} from "react-device-detect"
import _ from 'lodash'
import { connect } from 'react-redux'
const styles = theme => ({
    avatar: {
        height: 45,
        width: 45,
        margin:3,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 30,
            maxWidth: 30,
            margin:2,
        },
    },
    name: {
        ...theme.typography.body2,
        color: '#7d7d7d',
        paddingLeft: 4,
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.75rem'
        },
    },
})

class Avatars extends Component {

    render() {
        const {classes, auth, user_share, innerWidth ,metamor ,uuid} = this.props
        const  userInfo = auth.initialState.auth.user
        let i = (innerWidth === 'xs' && isMobileOnly) ? 3 : 5
        let j = 0
        let userShare = []
        if (!_.isEmpty(metamor)) {
            metamor.forEach(m=>{
                if (m.metamors_uuid === uuid) {
                    userShare.push(m)
                }
            })
        }
        if (!_.isEmpty(user_share) && !_.isEmpty(this.props.data) ){
            user_share.forEach(u=>{
                this.props.data.forEach(d=>{
                    if (u.user_id === d.user_uuid){
                        userShare.push(u)
                    }
                })

            })
        }
        let avatars = !_.isEmpty(userShare) && userShare.map((avatar, index) => {
            if (index < i) {
                return (
                    <Tooltip key={index} title={avatar.name ? avatar.name : avatar.nickname} placement="top">
                        <Avatar src={avatar.avatar ? avatar.avatar : `../img/no_avatar.png`} className={classes.avatar}/>
                    </Tooltip>
                )
            } else {
                j++
                return null
            }
        })
        return (
            <Grid container item xs={12}>
                <Grid container item xs={4} md={4} zeroMinWidth alignItems={"center"}>
                    <Avatar src={userInfo ? userInfo.avatar : `../img/no_avatar.png`} className={classes.avatar}/>
                    <span className={classes.name}>{userInfo ? userInfo.last_name : ''} {userInfo ? userInfo.first_name : ''}</span>
                </Grid>
                <Grid container item xs={8} md={8} alignItems={"center"} justify={"flex-end"}>
                    <span style={{paddingRight: 5}}>{j>0 && `&amp;`}</span>{avatars}{j > 0 ? <Avatar className={classes.avatar}> {j} </Avatar> : null}
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    (state)=>({
        metamor : state.metamor,
        auth :state.auth,
        user_share: state.user_share
    })
)(withStyles(styles)(Avatars))