import React from 'react';
import PropTypes from 'prop-types';
import { compose } from "redux";
import { connect } from "react-redux";
import { AppBar, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core';
import { AccountCircle, Apps, ShoppingCart, Search, Notifications} from '@material-ui/icons';
import AppListPopup from "./AppListPopup";
import AccountPopup from "./AccountPopup";
import { Link } from 'react-router-dom';
import LoginRegisterButtons from "./LoginRegisterButtons";
import ObjectPath from 'object-path';
import Avatar from "@material-ui/core/Avatar/Avatar";
const mapStateToProps = state => {
    return {
        auth: state.auth.initialState,
    }
};
const mapDispatchToProps = dispatch => ({});
const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    typography: {
        margin: theme.spacing.unit * 2,
    },
    avatar: {
        width: 40,
        height: 40,
    },
    icons: {
        fontSize: 30
    }
});

class MenuAppBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appListPopupAnchorEl: null,

        };
    }

    openAccountPopup = (e) => {
        this.setState({
            accountPopupAnchorEl: e.currentTarget
        })
    };

    closeAccountPopup = (e) => {
        this.setState({
            accountPopupAnchorEl: null
        })
    };
    closeAppListPopup = (e) => {
        this.setState({
            appListPopupAnchorEl: null
        })
    };
    showAppList = (e) => {
        this.setState({
            appListPopupAnchorEl: e.currentTarget
        })
    };

    render() {
        const { classes } = this.props;
        const { auth } = this.props.auth;
        const { nickname, avatar } = JSON.parse(window.sessionStorage.getItem('user') || '{}')
        return (
            <div className={classes.root}>
                <AppBar position="fixed" style={{ backgroundColor: '#3A3A3A', padding: '0',zIndex:10000 }} className="h-header">
                    <Toolbar>

                        <Typography color={"inherit"} variant="title" className={classes.flex}>
                            <Link style={{ color: "white" }} className={"noUnderline"} to="/?is_dev"><img src="../img/logo.png" alt="true" /></Link>
                        </Typography>

                        <IconButton color={"inherit"}
                                    onClick={() => {
                                        window.location = "https://cart2.geniam.com/"
                                    }}>
                            <ShoppingCart className={classes.icons}/>
                        </IconButton>
                        <IconButton color={"inherit"}>
                            <Search className={classes.icons}/>
                        </IconButton>
                        <IconButton color={"inherit"} onClick={this.showAppList}>
                            <Apps className={classes.icons}/>
                        </IconButton>
                        <IconButton color={"inherit"}>
                            <Notifications className={classes.icons}/>
                        </IconButton>
                        <AppListPopup
                            anchorEl={this.state.appListPopupAnchorEl}
                            onClose={this.closeAppListPopup}
                        />
                        {!ObjectPath.get(auth, 'isLogin') ?
                            (<LoginRegisterButtons/>) :
                            (
                                <div>
                                    <IconButton
                                        id={'user_avatar_button'}
                                        aria-owns={'menu-appbar'}
                                        aria-haspopup="true"
                                        onClick={this.openAccountPopup}
                                        color="inherit"
                                    >
                                        {
                                            avatar ?
                                                <Avatar
                                                    alt={nickname}
                                                    src={avatar}
                                                    className={classes.avatar}
                                                /> :
                                                <AccountCircle className={classes.avatar}/>
                                        }
                                    </IconButton>
                                    <AccountPopup
                                        last_name={ObjectPath.get(auth, 'user.last_name')}
                                        first_name={ObjectPath.get(auth, 'user.first_name')}
                                        anchorEl={this.state.accountPopupAnchorEl}
                                        onClose={this.closeAccountPopup}
                                    />
                                </div>
                            )
                        }
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(MenuAppBar);
