import React, {Component} from 'react'
import {Grid, CardMedia, withStyles} from '@material-ui/core'
import {ThumbUp} from "@material-ui/icons"
import Topic from "../Topic/Topic"

const styles = {
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
}

class MediaApp extends Component {
    render() {
        const {classes} = this.props
        return (
            <Grid item>
                <Grid container spacing={8}>
                    <Topic icon={<ThumbUp/>} title={"おすすめの企画"}/>
                    <Grid item xs={12}>
                        <CardMedia
                            component="img"
                            alt="Event"
                            className={classes.media}
                            image={process.env.PUBLIC_URL + '/img/event.png'}
                            title="Event"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CardMedia
                            component="img"
                            alt="Inner Tours"
                            className={classes.media}
                            image={process.env.PUBLIC_URL + '/img/InnerTours.png'}
                            title="Inner Tours"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CardMedia
                            component="img"
                            alt="Enocolor"
                            className={classes.media}
                            image={process.env.PUBLIC_URL + '/img/enecolor.png'}
                            title="Enocolor"
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(MediaApp)