import React, {Component} from "react"
import {Grid, withStyles} from "@material-ui/core"
import {Link} from "react-router-dom";

const styles = theme => ({
    title: {
        // ...theme.typography.title,
        ...theme.typography.h6,
        fontWeight: 'bold',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            paddingLeft: 5
        },
    },
})

class Topic extends Component {
    render() {
        const {classes, icon, title, linkTo, classLink, classTopic} = this.props;
        return (
            <Grid container item xs={12} className={classTopic}>
                <Grid item xs={2}>
                    {icon}
                </Grid>
                <Grid container item xs={10} className={classes.title} alignItems={"center"}>
                    {!linkTo ?
                        title :
                        <Link to={linkTo} className={classLink}>{title}</Link>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Topic)