import React, {Component} from 'react'
import Grids from "../components/AdvancedGrid/AdvancedGrid"
import CenterMainApp from "../components/Application/CenterMainApp"
import RightMainApp from "../components/Application/RightMainApp"
import LeftMainApp from "../components/Application/LeftMainApp"
import {isMobileOnly} from "react-device-detect"
import SwipeableViews from "react-swipeable-views"
import {Fab, withStyles, Hidden} from '@material-ui/core'
import {TouchApp, KeyboardArrowUp} from '@material-ui/icons'
import ScrollToTop from "react-scroll-up"

const styles = theme =>({
    fab: {
        margin: 8,
        backgroundColor: 'rgba(224, 224, 224, 0.4) !important',
        zIndex: 1200,
        position: 'fixed',
        bottom: 20,
    },
    formButtom: {
        float: 'left',
        position: 'relative'
    },
    buttonTop: {
        backgroundImage: 'linear-gradient(45deg, #4caf50 30%, #56ab2f 90%)',
        opacity: 0.8,
        margin: theme.spacing.unit,
    }
})

export default withStyles(styles)(class Content extends Component {
    state = {
        value: 1
    }
    handleChange = (e) => {
        e.preventDefault()
        console.log(this.state)
        let values = this.state.value + 1
        if (values === 3) {
            values = 0
        }
        this.setState({value: values})
    }

    handleChangeIndex = index => {
        console.log(this.state.value)
        this.setState({value: index})
        // setValue(index)
    }
    render() {
        const {classes} = this.props
        const {value} = this.state
        let hiddenBtlTop = (value === 0 || value === 2) ? true : false
        return (
            <div style={{maxWidth: 1280, margin: 'auto', marginTop: 80}}>
                {isMobileOnly ?
                    <Grids container style={{minWidth: 320}}>
                        <SwipeableViews
                            axis="x"
                            index={value}
                            onChangeIndex={this.handleChangeIndex}
                            animateHeight={value === 1 ? false : true}
                        >
                            <LeftMainApp xs={24}/>
                            <CenterMainApp xs={24} sm={18} md={15}/>
                            <RightMainApp/>
                        </SwipeableViews>
                        <form onSubmit={this.handleChange} className={classes.formButtom}>
                            <Fab color="inherit" size="small" aria-label="Add" className={classes.fab} type="submit">
                                <TouchApp/>
                            </Fab>
                        </form>
                    </Grids> :
                    <Grids container style={{minWidth: 767}}>
                        <LeftMainApp xs={5} lg={4}/>
                        <CenterMainApp xs={13}/>
                        <RightMainApp/>
                    </Grids>
                }
                <Hidden xsUp={hiddenBtlTop}>
                    <ScrollToTop showUnder={160} style={{zIndex: 1200, bottom: 20, right: 0}}>
                        <Fab color="inherit" size="small" className={classes.buttonTop}>
                            <KeyboardArrowUp/>
                        </Fab>
                    </ScrollToTop>
                </Hidden>
            </div>
        )
    }
})