import React, {Component} from 'react';
import {Grid, withStyles} from '@material-ui/core';
import MediaBox from "../MediaBox/MediaBox";

const styles = theme => ({
    title: {
        fontWeight: 'bold',
        paddingBottom: 0,
    },
    content: {
        ...theme.typography.body1,
        fontSize: '0.75rem',
    },
    colorLink: {
        color: '#000'
    },
    mediaBox: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    }
})

class Advertisement extends Component {

    render() {
        const {classes, title, img, content, linkTo} = this.props;
        return (
            <Grid item xs={12}>
            <Grid container item xs={12} spacing={8} style={{margin:0}}>
                {!img
                    ? <MediaBox
                        title={title}
                        classTitle={classes.title}
                        linkTo={linkTo}
                        classLink={classes.colorLink}
                        classMediaBox={classes.mediaBox}
                    />
                    : <MediaBox
                        title={title}
                        img={img}
                        classTitle={classes.title}
                        linkTo={linkTo}
                        classLink={classes.colorLink}
                    />
                }
                {!content ? null
                    : <Grid item xs={12} className={classes.content}>{content}</Grid>
                }
            </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Advertisement);