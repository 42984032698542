import React, {Component} from "react";
import KomaDescription from "../components/KomaDescription/KomaDescription";
import {Grid, withStyles} from "@material-ui/core";
import MenuAppBar from "../components/Application/MenuAppBar/MenuAppBar";
import Footer from "../components/Application/Footer/Footer";
import {isMobileOnly} from "react-device-detect";
import {LoadingOverlay, Loader} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { connect } from 'react-redux'
import axios from 'axios'
import {GET_KOMA_LIST} from "../config/apiUrl";
import moment from 'moment'
import _ from 'lodash'
import AlertDialogSlide from "../components/Dialog/Cancel";
const styles = {
    title: {
        fontSize: '1.375rem',
        color: '#aeacac',
        textAlign: 'center',
        margin: '30px 0px 18px 0px'
    }
}

class ComaList extends Component {
    componentDidMount = () => {
        const {dispatch} = this.props
        dispatch({
            type: 'SET_LOADING_TRUE'
        })
        axios.get(GET_KOMA_LIST)
            .then(res => {
                dispatch({
                    type: 'SET_LOADING_FALSE'
                })
                dispatch({
                    type: 'SET_KOMA',
                    koma: res.data
                })
            })
            .catch(e => {
                dispatch({
                    type: 'SET_LOADING_FALSE'
                })
                console.log(e)
            })
    }

    render() {
        const {classes, loading, koma} = this.props
        let arr1 =[] // new register
        let arr2 = [] // old register
        let arr3 = [] // cancel register
        let arr4 = [] // not buy
        if (!_.isEmpty(koma)) {
            koma.forEach(k=>{
                if (k.is_deleted || !k.is_accepted) {
                    arr3 =_.concat(arr3,k)
                }else {
                    if (moment.parseZone(k.selected_time).local().format() > moment().local().format()) {
                        if(k.is_did_buy){
                            arr1 = _.concat(arr1,k)
                        }else {
                            arr4 = _.concat(arr4,k)
                        }
                    }else {
                        arr2 = _.concat(arr2,k)
                    }
                }
            })
        }
        let size = isMobileOnly ? 320 : 767
        return (
            <div>
                <MenuAppBar/>
                <LoadingOverlay>
                    <Loader loading={loading} text={''}/>
                    <Grid container justify={'center'} style={{marginTop: 70, minWidth: size}}>
                        <Grid container item xs={12} md={9} lg={8} spacing={8} justify={'center'}>
                            <Grid item xs={12} className={classes.title}>
                                予約済一覧
                            </Grid>
                            {!_.isEmpty(arr1)? arr1.map((k,index)=>{
                                return(
                                    <KomaDescription
                                        NumberKey={index+1}
                                        title={k.schedules[0].title}
                                        img={''}
                                        NumberOn={1}
                                        OnTo={1}
                                        Coma={k.coma}
                                        Minute={k.coma * 50}
                                        content={k.schedules[0].metamor.profile.description}
                                        linkAddApp
                                        serviceName={k.schedules[0].service_name}
                                        calendarTitle={'予約済み'}
                                        month={moment.parseZone(k.schedules[0].start).local().month() + 1}
                                        day={moment.parseZone(k.schedules[0].start).local().date()}
                                        hourStart={moment.parseZone(k.selected_time).local().format('HH:mm')}
                                        hourEnd={moment.parseZone(k.selected_time).local().add(k.coma *50,'m').format('HH:mm')}
                                        data={k}
                                    />
                                )
                            }):''}

                        </Grid>
                        <Grid container item xs={12} md={9} lg={8} spacing={8} justify={'center'}>
                            <Grid item xs={12} className={classes.title}>
                                完了
                            </Grid>

                            {!_.isEmpty(arr2)? arr2.map((k,index)=>{
                                return(
                                    <KomaDescription
                                        NumberKey={index+1}
                                        title={k.schedules[0].title}
                                        img={''}
                                        NumberOn={1}
                                        OnTo={1}
                                        Coma={k.coma}
                                        Minute={k.coma *50}
                                        content={k.schedules[0].metamor.profile.description}
                                        linkAddApp
                                        serviceName={k.schedules[0].service_name}
                                        calendarTitle={'完了'}
                                        month={moment.parseZone(k.schedules[0].start).local().month() + 1}
                                        day={moment.parseZone(k.schedules[0].start).local().date()}
                                        hourStart={moment.parseZone(k.selected_time).local().format('HH:mm')}
                                        hourEnd={moment.parseZone(k.selected_time).local().add(k.coma *50,'m').format('HH:mm')}
                                        data={k}
                                    />
                                )
                            }):''}

                        </Grid>
                        <Grid container item xs={12} md={9} lg={8} spacing={8} justify={'center'}>
                            <Grid item xs={12} className={classes.title}>
                                Not Buy
                            </Grid>

                            {!_.isEmpty(arr4)? arr4.map((k,index)=>{
                                return(
                                    <KomaDescription
                                        NumberKey={index+1}
                                        title={k.schedules[0].title}
                                        img={''}
                                        NumberOn={1}
                                        OnTo={1}
                                        Coma={k.coma}
                                        Minute={k.coma *50}
                                        content={k.schedules[0].metamor.profile.description}
                                        linkAddApp
                                        serviceName={k.schedules[0].service_name}
                                        calendarTitle={'完了'}
                                        month={moment.parseZone(k.schedules[0].start).local().month() + 1}
                                        day={moment.parseZone(k.schedules[0].start).local().date()}
                                        hourStart={moment.parseZone(k.selected_time).local().format('HH:mm')}
                                        hourEnd={moment.parseZone(k.selected_time).local().add(k.coma *50,'m').format('HH:mm')}
                                        data={k}
                                        NotBuy={1}
                                    />
                                )
                            }):''}

                        </Grid>
                        <Grid container item xs={12} md={9} lg={8} spacing={8} justify={'center'}>
                            <Grid item xs={12} className={classes.title}>
                                DELETED
                            </Grid>
                            {!_.isEmpty(arr3)? arr3.map((k,index)=>{
                                return(
                                    <KomaDescription
                                        NumberKey={index+1}
                                        title={k.schedules[0].title}
                                        img={''}
                                        NumberOn={1}
                                        OnTo={1}
                                        Coma={k.coma}
                                        Minute={k.coma *50}
                                        content={k.schedules[0].metamor.profile.description}
                                        linkAddApp
                                        serviceName={k.schedules[0].service_name}
                                        calendarTitle={'完了'}
                                        month={moment.parseZone(k.schedules[0].start).local().month() + 1}
                                        day={moment.parseZone(k.schedules[0].start).local().date()}
                                        hourStart={moment.parseZone(k.selected_time).local().format('HH:mm')}
                                        hourEnd={moment.parseZone(k.selected_time).local().add(k.coma *50,'m').format('HH:mm')}
                                        Rejected={!k.is_accepted}
                                        data={k}
                                    />
                                )
                            }):''}

                        </Grid>
                    </Grid>
                </LoadingOverlay>
                <AlertDialogSlide/>
                <Footer/>
            </div>
        )
    }
}

export default connect(
    (state)=>({
        loading: state.loading,
        koma: state.koma
    })
)(withStyles(styles)(ComaList))