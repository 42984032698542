import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import React from 'react';
import {history, store} from './store';
import {Router} from 'react-router-dom'
import Auth from './auth';
import App from "./App";
import configAxios from './config/axios'
import {unregister} from "./registerServiceWorker";

configAxios()

Auth.Core.setToken("token", {"store": store}).auth(function () {
    ReactDOM.render((
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    ), document.getElementById('root'));
});

unregister();

window.core = Auth.Core;


