import React from 'react'
import NavApp from "./NavApp"
import InsightMindContent from "../ContentMain/ContentMain"
import Grids from "../AdvancedGrid/AdvancedGrid"
import {Grid, withStyles, withWidth} from "@material-ui/core";
import {connect} from "react-redux";
import {SET_INSIGHT_MAP} from "../../constants/actionTypes";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from '@material-ui/core/CircularProgress';
import StarDialog from "../Dialog/Star";
import {GET_INSIGHTMAP} from "../../config/apiUrl";
import PinDialog from "../Dialog/Pin";
import { getTask } from "../../actions/task";

const styles = theme => ({
    title: {
        ...theme.typography.h6,
        fontWeight: 'bold',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            paddingLeft: 5
        },
    },
})

class CenterMain extends React.Component {
    state = {
        loading: false,
        data: [],
        page: 0,
        perPage: 10,
        length: 10
    }

    fetchData = () => {
        let {dispatch} = this.props;
        this.setState({
            loading: true,
        });
        axios.post(GET_INSIGHTMAP, {
            user_id: this.props.auth.auth.user.user_id,
            page: this.state.page,
            perPage: this.state.perPage
        }).then(async res => {
            this.setState({
                loading: false,
                page: this.state.page + 1,
                per_page: this.state.per_page,
                length: this.state.page * 2
            });
             dispatch({
                type: 'SET_METAMOR',
                metamor: res.data.metamor
            })
            await dispatch({
                type: SET_INSIGHT_MAP,
                data: res.data ? res.data.insightmaps : {},
                page: res.data.insightmaps.page,
                per_page: res.data.insightmaps.perPage
            });
            dispatch({
                type: 'SET_USER_SHARE',
                user_share: res.data.user_share
            })
            getTask()
        })
            .catch(err => {
                console.log(err)
            })
    }

    componentDidMount() {
        this.fetchData()
    }

    render() {
        const {classes, xs, sm, md, width, loadpage, insightMap} = this.props
        let spacing = (width === 'xs') ? 8 : (width === 'sm') ? 16 : 32
        let condition = this.props.insightMap.page <= this.props.insightMap.total / 10
        if (loadpage === 1 && this.props.insightMap.page <= this.props.insightMap.total / 10) {
            condition = true;
        }
        let pinArr = []
        if (insightMap && insightMap.data) {
            insightMap.data.forEach(i => {
                if (i.is_pin) {
                    pinArr.push(i)
                }
            })
        }
        return (
            <Grids item xs={xs} sm={sm} md={md} lg={14} style={{margin: '0px auto'}}>
                <Grid container style={{padding: 8}}>
                    <Grid container spacing={16}>
                        <Grid container item xs={12}>
                            <NavApp/>
                        </Grid>
                        <Grid container item xs={12} style={{padding: 0, paddingLeft: 20}}>
                            <Grid container item xs={1} alignItems={"center"} style={{maxWidth: 25}}>
                                <img src={process.env.PUBLIC_URL + "/img/icon02.png"} alt={'Insight Mind'}/>
                            </Grid>
                            <Grid container item xs={11} alignItems={"center"} className={classes.title}>
                                並び替え
                            </Grid>

                        </Grid>
                        <Grid container item xs={12} spacing={spacing} style={{margin: 0, paddingTop: 0}}>
                            <div style={{overflow: 'auto'}}>
                                {this.props.tab === 3 &&
                                <InfiniteScroll
                                    dataLength={this.props.insightMap.data ? this.props.insightMap.data.length : 0}
                                    next={condition ? this.fetchData : false}
                                    hasMore={condition}
                                    loader={<h3 style={{textAlign: 'center'}}><CircularProgress/></h3>}
                                    scrollThreshold={0.9}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {this.props.insightMap && this.props.insightMap.data && this.props.insightMap.data.map((value, index) => {
                                        return (
                                            <InsightMindContent
                                                key={index}
                                                id={index}
                                                data={value}
                                            />
                                        )})}
                                </InfiniteScroll>
                                }
                                {
                                    this.props.tab === 1 &&
                                    pinArr.map((value, index) => {
                                        return (
                                            <InsightMindContent
                                                key={index}
                                                id={index}
                                                data={value}
                                            />
                                        )
                                    })
                                }

                                <StarDialog/>
                                <PinDialog/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grids>
        )
    }
}

export default connect(
    (state) => ({
        insightMap: state.insightMap,
        auth: state.auth.initialState,
        tab: state.tab
    })
)(withWidth()(withStyles(styles)(CenterMain)))