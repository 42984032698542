import React, {Component} from 'react'
import {Grid, withStyles, ClickAwayListener, Paper, Fab} from '@material-ui/core'
import {Close} from "@material-ui/icons"
import {connect} from 'react-redux'
import 'react-tippy/dist/tippy.css'
import {Tooltip} from 'react-tippy'
import Typography from "@material-ui/core/Typography/Typography"
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Button from "@material-ui/core/es/Button/Button";
import axios from 'axios'
import { UPDATE_IDENTITIES } from "../../config/apiUrl";

const styles = theme => ({
    root:{
        flexGrow:1,
    },

    button: {
        border: '4px solid #62a841',
        height: 22,
        minWidth: 22,
        minHeight: 0,
        borderRadius: 0,
        marginRight: 8,
        float: 'left'
    },
    buttonClose: {
        backgroundColor: 'white',
        maxHeight: 22,
        minHeight: 1,
        width: 20,
        position: 'absolute',
        right: '-2%',
        top: '-4%',
        zIndex: 10001,
        cursor: 'pointer'
    },
    titAll: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        position: 'relative',
        float: 'right',
        overFlow: 'hidden',
    },
    imageIcon: {
        position: 'relative',
    },
    padding: {
        paddingBottom: 2,
    }
});

class CheckUsers extends Component{
    state = {
        open : false
    }
    setIsOpen =()=>{
        this.setState({
            open : !this.state.open
        })
    }
    setIsClose =()=>{
        this.setState({
            open : false
        })
    }
    getImg = ()=>{
        let res = "../img/icon06.png"
        this.props.identities.forEach(t=>{
            if (t.is_selected){
                res = `../static/images/identity/${t.color}.png`
            }
        })
        return res
    }
    handleChange = (id,insightmap_uuid)=>{
        let nextIdentities =JSON.parse(JSON.stringify(this.props.identities))
        nextIdentities.forEach(i=>{
            if (i.id === id){
                i.is_selected = 1
            }else {
                i.is_selected = 0
            }
        })
        axios.post(UPDATE_IDENTITIES,{
            identities:nextIdentities
        }).then(res=>{
            console.log(res.data)
            if (res.data){
                this.props.dispatch({
                    type: 'CHANGE_IDENTITIES',
                    identities : nextIdentities,
                    uuid:insightmap_uuid
                })
            }
        }).catch(e=>{
            console.log(e.response)
        }).finally(
            this.setIsClose()
        )

    }
    render() {
        const {classes, fontSize} = this.props
        return (

            <Tooltip
                position="bottom-end"
                animation="scale"
                arrow="true"
                arrowSize="big"
                theme="light"
                trigger="click focus"
                interactive
                zIndex
                open={this.state.open}
                html={(
                    <div className={classes.root}>
                        <Fab
                            className={classes.buttonClose}
                            onClick={this.setIsClose}>
                            <Close style={{fontSize: 16}}/>
                        </Fab>
                        <ClickAwayListener onClickAway={this.setIsClose}>
                            <div>
                                <Grid style={{padding: 10}}>
                                    <Paper elevation={0} className={classes.titAll}>
                                        <Typography component="p" style={{float: 'right', fontSize: fontSize}}>
                                            りやって.カテゴ
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid container item xs={12} style={{minWidth: 350}}>
                                    <Grid container item xs={10}>
                                        {this.props.identities.map((value, index) => {
                                            return (
                                                <Grid item xs={3} key={index} style={{paddingBottom: 10,}}>
                                                    <Button
                                                        className={classes.padding}
                                                        onClick={()=>this.handleChange(value.id,value.insightmap_uuid)}>
                                                        <img src={`../static/images/identity/${value.color}.png`}
                                                             alt=""/>
                                                    </Button>
                                                    <Typography component="p"
                                                                style={{fontSize: fontSize, padding: '0px 5px'}}>
                                                        {value.content}
                                                    </Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </div>
                        </ClickAwayListener>
                    </div>
                )}
            >
                <Checkbox
                    style={{padding: 10}}
                    checked={false}
                    onClick={this.setIsOpen}
                    color="default"
                    icon={<img
                        src={this.getImg()}
                        alt=""
                        style={{position: 'relative' ,width:'13px',height:'21px'}}
                    />}
                />
            </Tooltip>

        );
    }
}

export default connect(
    (state) => ({
        color: state.color
    })
)(withStyles(styles)(CheckUsers))