import * as TypeS from './../constants/actionTypes';
import axios from 'axios';
import {GET_SCHEDULES_CONTENTS} from "../config/apiUrl";


export const fetchSchedules = () => {
    return dispatch => {
        return axios.post( GET_SCHEDULES_CONTENTS, {
            contents: "insightmap",
            date: new Date()
        }).then(res => {
            console.log('Tuan...actions/Schedule 17...', res)
            dispatch(setSchedules(res.data[0]))
        }).catch(err => {
            console.log('...actions/Schedules 18...', err.response)
        })
    }
}

export const setSchedules = (schedule) => {
    return {
        type: TypeS.FETCH_SCHEDULES,
        schedule
    }
}