import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {connect} from 'react-redux'
import axios from 'axios'
import {STAR_UPDATE} from "../../config/apiUrl";
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class StarDialog extends React.Component {

    handleClose = () => {
        this.props.dispatch({
            type: 'SET_OPEN_STAR_DIALOG_FALSE'
        })
    };
    update = () => {

        this.props.dispatch({
            type: 'SET_LOADING_TRUE'
        })
        axios.post(STAR_UPDATE, {
            uuid: this.props.selectingInsightmap.uuid,
            star: this.props.selectingInsightmap.star
        }).then(res => {
            this.props.dispatch({
                type: 'UPDATE',
                insightmap: this.props.selectingInsightmap
            })
            this.props.dispatch({
                type: 'SET_LOADING_FALSE'
            })
            this.props.dispatch({
                type: 'SET_OPEN_STAR_DIALOG_FALSE'
            })
        }).catch(e => {
            console.log(e)
            this.props.dispatch({
                type: 'SET_LOADING_FALSE'
            })
            this.props.dispatch({
                type: 'SET_OPEN_STAR_DIALOG_FALSE'
            })
        })
    }

    render() {
        const {openStarDialog, loading} = this.props
        return (
            <div>
                <Dialog
                    open={openStarDialog}
                    TransitionComponent={Transition}
                    onClose={this.handleClose}
                    aria-labelledby="star-dialog-slide-title"
                    aria-describedby="star-dialog-slide-description"
                >
                    <LoadingOverlay>
                        <Loader loading={loading} text={''}/>
                        <DialogTitle id="star-dialog-slide-title">
                            {"RATING "}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="star-dialog-slide-description">
                                DO YOU WANT RATE THIS CONTENT {this.props.selectingInsightmap.star} star ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleClose}
                                color="primary"
                                disabled={loading}
                            >
                                Disagree
                            </Button>
                            <Button
                                onClick={this.update}
                                color="primary"
                                disabled={loading}
                            >
                                Agree
                            </Button>
                        </DialogActions>
                    </LoadingOverlay>
                </Dialog>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        openStarDialog: state.openStarDialog,
        selectingInsightmap: state.selectingInsightmap,
        insightMap: state.insightMap,
        loading: state.loading
    })
)(StarDialog);
