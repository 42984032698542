import React from "react"
import StickyBox from "react-sticky-box"
import {Grid} from "@material-ui/core"
import ProfilesApp from "./ProfileApp"
import ProgressApp from "./ProgressApp"
import EventApp from "./EventApp"
import CommunityApp from "./CommunityApp"
import MediaApp from "./MediaApp"
import Grids from "../AdvancedGrid/AdvancedGrid"

export default function LeftMainApp(props) {
    const {xs, lg} = props
    return (
        <Grids item xs={xs} lg={lg} style={{maxWidth: 750}}>
            <StickyBox offsetBottom={20} offsetTop={70}>
                <Grid style={{padding: 8}}>
                    <Grid container spacing={16}>
                        <ProfilesApp/>
                        <ProgressApp/>
                        <EventApp/>
                        <CommunityApp/>
                        <MediaApp/>
                    </Grid>
                </Grid>
            </StickyBox>
        </Grids>
    )
}