import axios from "axios"
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from './constants'
import apiUrl, {REFRESH_TOKEN_URL} from './apiUrl'
import removeAllTokens from './removeAllTokens'
import localToken from "../common/localToken";

export default async function () {
    console.log("refreshToken()")
    const {refreshToken} = localToken()
    if (!refreshToken) {
        // goto login
        let url = apiUrl.LOGIN + '?redirect_url=' + window.location.href;
        window.location.assign(url);
        return
    }
    try {
        console.log("refreshing Access Token")
        const res = await axios.post(REFRESH_TOKEN_URL, {refreshToken})
        const data = res.data;
        if (data.refreshToken) {
            localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken)
        }
        localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken)
        console.info("Access Token Refreshed")
        return data
    } catch (e) {
        removeAllTokens()
        // goto login
        let url = apiUrl.LOGIN + '?redirect_url=' + window.location.href;
        window.location.assign(url);
        return e
    }
}