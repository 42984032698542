import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux'
import axios from 'axios'
import { CANCEL_KOMA } from "../../config/apiUrl";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AlertDialogSlide extends React.Component {

    handleClose = () => {
        this.props.dispatch({
            type:'SET_OPEN_CANCEL_DIALOG_FALSE'
        })
    };
    handleCancel = () =>{
        this.props.dispatch({
            type:'SET_OPEN_CANCEL_DIALOG_FALSE'
        })
        this.props.dispatch({
            type :'SET_LOADING_TRUE'
        })
        axios.post(CANCEL_KOMA,{
            uuid: this.props.selectingKoma.uuid
        }).then(res=>{
            this.props.dispatch({
                type :'SET_LOADING_FALSE'
            })
            this.props.dispatch({
                type:'UPDATE_CANCEL',
                uuid:this.props.selectingKoma.uuid
            })
        }).catch(e=>{
            console.log(e)
            this.props.dispatch({
                type :'SET_LOADING_FALSE'
            })
        })
    }

    render() {
        const { openCancelDialog } = this.props
        return (
            <div>
                <Dialog
                    open={openCancelDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Cancel schedule "}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            解説ああああああああああああああああああああああああああああああああああああああああああああああああああああああ
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this.handleCancel} color="primary">
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(
    (state)=>({
        openCancelDialog: state.openCancelDialog,
        selectingKoma:state.selectingKoma
    })
)(AlertDialogSlide);
