import _ from 'lodash'
export default (state =[],action)=>{
    switch (action.type) {
        case 'SET_TASK':
            return action.tasks
        case 'UPDATE_TASK_PROCESS':
            const idx = _.findIndex(state,{'uuid':action.uuid})
            console.log(idx)
            const nextState = [...state]
            if (idx !== -1){
                nextState.splice(idx,1,action.task)
            }
            return nextState
        default:
            return state
    }
}