import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import home from './reducers/home';
import schedules from './reducers/schedules'
import schedulesRS from './reducers/SchedulesRS'
import { routerReducer } from 'react-router-redux';
import color from './reducers/ColorContent'
import content from './reducers/Content'
import insightMap from './reducers/insightMap'
import loading from './reducers/loading'
import koma from './reducers/komalist'
import openCancelDialog from './reducers/openCancelDialog'
import selectingKoma from './reducers/SelectingKoma'
import openStarDialog from './reducers/openStarDialog'
import selectingInsightmap from  './reducers/SelectingInsightmap'
import openPinDialog from './reducers/openPinDialog'
import metamor from './reducers/metamor'
import user_share from  './reducers/userShare'
import tab from './reducers/tab'
import tasks from './reducers/task'
export default combineReducers({
  auth,
  common,
  home,
  schedules,
  schedulesRS ,
  color,
  insightMap,
  content,
  loading,
  koma,
  openCancelDialog,
  selectingKoma,
  openStarDialog,
  selectingInsightmap,
  openPinDialog,
  metamor,
  user_share,
  tab,
  tasks,
  router: routerReducer
});
