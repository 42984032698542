import React, {Component} from 'react';
import {Grid, withStyles ,Button} from "@material-ui/core";
import SeviceName from "../SeviceName/SeviceName";
import KomaRight from "./KomaRight";
import KomaLeft from "./KomaLeft";
import { CALENDAR_SAME_SCHEDULES } from "../../config/apiUrl";

const styles = theme => ({
    root: {
        flexGrow: 1,
        borderRadius: 10,
        backgroundColor: '#FFF',
        color: '#000',
        margin: '12px 0px',
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
    LinkProgram: {
        fontSize: '0.6875rem',
        display: 'grid',
        textAlign: 'right',
        justifyContent: 'right',
        alignItems: 'end',
    },
    title:{
        fontSize: "1.375rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: '1rem',
        },
    },
    content: {
        fontSize: "0.9375rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: '0.875rem',
        },
    }
})

class KomaDescription extends Component {
    SameCalendar = () =>{
        window.open(CALENDAR_SAME_SCHEDULES,'_blank')
    }
    render() {
        const {
            classes,
            NumberKey = -1,
            title = '何のタイトルコンテンツありません',
            img,
            NumberOn,
            OnTo,
            Coma,
            Minute,
            content = '説明的な内容はありません。',
            serviceName,
            calendarTitle,
            month,
            day,
            hourStart,
            hourEnd,
            linkCancel,
            linkCC,
            linkRelated,
            Rejected
        } = this.props;
        return (
            <Grid container item xs={12} spacing={16}>
                <Grid item xs={12} className={classes.root}>
                    <Grid container item xs={12} spacing={8} style={{margin: 0}}>
                        <Grid item xs={12} md={6} className={classes.title}>
                            {(NumberKey > 0) ? NumberKey + '.' + title : title }
                            {Rejected? '(Metamor Rejected)' : ''}
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.LinkProgram}>
                            <Button onClick={this.SameCalendar} style={{color: '#29abe2'}}>
                                このプログラムをさらに予約
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={8} style={{margin: 0}}>
                        <Grid item xs={12} sm={12} md={4}>
                            <KomaLeft
                                title={title}
                                img={img}
                                NumberOn={NumberOn}
                                OnTo={OnTo}
                                Coma={Coma}
                                Minute={Minute}
                            />
                        </Grid>
                        <Grid item xs={8} sm={9} md={6} className={classes.content}>
                            {content}
                        </Grid>
                        <Grid item xs={4} sm={3} md={2}>
                            <Grid container spacing={8}>
                                <Grid container item xs={12} justify={"flex-end"}>
                                    <SeviceName
                                        serviceName={serviceName}
                                    />
                                </Grid>
                                <KomaRight
                                    calendarTitle={calendarTitle}
                                    month={month}
                                    day={day}
                                    hourStart={hourStart}
                                    hourEnd={hourEnd}
                                    linkCancel={linkCancel}
                                    linkCC={linkCC}
                                    linkRelated={linkRelated}
                                    data={this.props.data}
                                    NotBuy={this.props.NotBuy}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(KomaDescription);