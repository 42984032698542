import React, {Component} from 'react';
import {Grid, withStyles} from '@material-ui/core';
const styles = theme => ({
    month: {
        ...theme.typography.h6,
        fontSize: '0.6875rem',
        backgroundColor: '#3a3a3a',
        color: '#FFF',
        height: 17
    },
    day: {
        ...theme.typography.h6,
        fontSize: '1.25rem',
        height: 35,
        textAlign: "center",
        // backgroundColor: '#fff',
    }
})

class Calendar extends Component {
    render() {
        const {
            classes,
            month = 0,
            day = 0,
            classCalendar,
            classDay
        } = this.props;
        return (
            <Grid container item className={classCalendar}>
                <Grid item xs={12} className={classes.month}>
                    {month}月
                </Grid>
                <Grid container item xs={12} className={classes.day + ' ' + classDay} alignItems={"center"} justify={"center"}>
                    {day} (土)
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Calendar);