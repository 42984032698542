import {SET_INSIGHT_MAP} from "../constants/actionTypes";
import _ from 'lodash'
export default (state = {}, action) => {
    switch (action.type) {
        case SET_INSIGHT_MAP:
            if(_.isEmpty(state)){
                return action.data
            }else {
                let newState = {...state}
                newState.data = _.concat(newState.data, action.data.data)
                newState.page = action.data.page
                return newState
            }
        case 'UPDATE':
            const index = _.findIndex(state.data,{uuid:action.insightmap.uuid})
            const newState = {...state}
            if (index !== -1){
                newState.data.splice(index,1, action.insightmap)
                console.log(' index ',newState.data[index])
            }
            return newState
        case 'ORDER':
            const nextState = {...state}
            nextState.data = _.orderBy(nextState.data,['is_pin'],['desc'])
            return nextState
        case  'SET_CHOOSE_COLOR':
            let idx = _.findIndex(state.data,{uuid:action.uuid})
            let newstate = {...state}
            if (idx !== -1){
                newstate.data[idx] = {...newstate.data[idx],tags: action.tags}
            }
            return newstate
        case  'CHANGE_IDENTITIES':
            const i = _.findIndex(state.data,{uuid:action.uuid})
            const next = {...state}
            if (idx !== -1){
                next.data[i] = {...next.data[i],identities: action.identities}
            }
            return next
        default:
            return state;
    }
};