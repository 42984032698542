import React, {Component} from 'react';
import {Grid, Hidden} from '@material-ui/core';
import {School} from '@material-ui/icons';
import {fetchSchedules} from "../../actions/Schedule";
import {connect} from 'react-redux';
import * as moment from 'moment';
import Event from "../Event/Event";
import Topic from "../Topic/Topic";

class EventApp extends Component {

    componentWillMount() {
        this.props.actionfetchSchedules();
    }

    render() {
        const {Schedules} = this.props
        let {title, serviceName, hourStart, hourEnd} = ''
        let {month, day} = -1
        let hidden = false
        if (!Schedules[0]) {
            hidden = true
        } else {
            title = Schedules[0].title
            serviceName = Schedules[0].service_name
            let Start = moment(Schedules[0].start)
            let End = moment(Schedules[0].end)
            month = Start.get('month') + 1;
            day = Start.get('date')
            hourStart = Start.hour() + ':' + Start.minute()
            hourEnd = End.hour() + ':' + End.minute()
        }
        return (
            <Grid item>
                <Grid container spacing={8}>
                    <Topic icon={<School/>} title={"予約済みのイベント"}/>
                    <Event
                        title={'イベントタイトルイベント'}
                        img={process.env.PUBLIC_URL + '/img/event.png'}
                        serviceName={'even'}
                        month={'8'}
                        day={'7'}
                        hourStart={'20:30'}
                        hourEnd={'22:00'}
                    />
                    <Hidden xsUp={hidden}>
                        <Event
                            title={title}
                            img={process.env.PUBLIC_URL + '/img/InnerTours.png'}
                            serviceName={serviceName}
                            month={month}
                            day={day}
                            hourStart={hourStart}
                            hourEnd={hourEnd}
                        />
                    </Hidden>
                    <Event
                        title={'イベントタイトルイベント'}
                        img={process.env.PUBLIC_URL + '/img/enecolor.png'}
                        serviceName={'enecolor'}
                        month={'10'}
                        day={'20'}
                        hourStart={hourStart}
                        hourEnd={hourEnd}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        Schedules: state.schedulesRS
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        actionfetchSchedules: () => {
            dispatch(fetchSchedules())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventApp)