import React, {Component} from 'react';
import {Grid, withStyles, LinearProgress} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: '0px ' + theme.spacing.unit + 'px !important'
    },
    title: {
        ...theme.typography.subheading,
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]:{
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]:{
            fontSize: '0.75rem',
        },
    },
    progress: {
        height: 10,
        border: 2 + 'px solid',
        borderColor: '#3a3a3a',
    },
    point: {
        textAlign: 'center',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]:{
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]:{
            fontSize: '0.75rem',
            paddingLeft: 3,
        },
    }
});

class Progress extends Component {
    state = {
        completed: 0,
    };

    componentDidMount() {
        const {timeProgress = 0} = this.props;
        this.timer = setInterval(this.progress, timeProgress);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const {point = 0} = this.props;
        const {completed} = this.state;
        if (completed < point) {
            const diff = 1;
            this.setState({completed: completed + diff});
        }
    };

    addZero = (value) => {
        return (value < 10) ? '0' + Number(value) : value;
    };

    render() {
        const {
            classes,
            keyNumber,
            colorOfPoint = '#28afb4',
            backgroundColorPoint = '#FFF',
            point = 0,
            maxPoint = 12,
            title
        } = this.props;
        const normalise = value => value * 100 / maxPoint;
        const {completed} = this.state;
        return (
            <Grid item xs={12} className={classes.root}>
                <Grid item xs={12} className={classes.title}>{title}</Grid>
                <style type="text/css">
                    {
                        `.colorPoint${keyNumber} {background-color: ${colorOfPoint};}`
                    }
                </style>
                <Grid container item xs={12}>
                    <Grid item xs={9} style={{margin: 'auto'}}>
                        <LinearProgress
                            variant="determinate"
                            value={normalise(completed)}
                            className={classes.progress}
                            classes={{
                                barColorPrimary: `colorPoint${keyNumber}`,
                            }}
                            style={{backgroundColor: backgroundColorPoint}}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.point}>
                        {this.addZero(point)}/{this.addZero(maxPoint)}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Progress);