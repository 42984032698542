import React, {Component} from 'react'
import {Button, ClickAwayListener, Fab, Grid, Paper, withStyles} from '@material-ui/core'
import {Close} from "@material-ui/icons"
import {connect} from 'react-redux'
import 'react-tippy/dist/tippy.css'
import {Tooltip} from 'react-tippy'
import Typography from "@material-ui/core/Typography/Typography"
import {CONTENT} from "../../constants/actionTypes"
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import _ from 'lodash'
import axios from 'axios'
import {UPDATE_TAGS} from "../../config/apiUrl";
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 400
    },
    button: {
        border: '4px solid #62a841',
        height: 22,
        minWidth: 24,
        minHeight: 0,
        borderRadius: 0,
        marginRight: 8,
        float: 'left',
        padding: 2,
        lineHeight: '100%',

    },
    listBox: {
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        borderRadius: 0,
        borderColor: '#62a841',
        float: 'left',
        borderRight: '4px solid',
        borderBottom: '4px solid',
    },
    listText: {
        width: '25%',
        padding: '0px 7px',
        webkitBoxSizing: 'border-box',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 0,
        msFlex: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    borderTopLeft: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        borderTop: '4px solid',
        borderLeft: '4px solid',
        float: 'left'
    },
    buttonClose: {
        backgroundColor: 'white',
        maxHeight: 22,
        minHeight: 1,
        width: 20,
        position: 'absolute',
        right: '-2%',
        top: '-6%',
        zIndex: 10001,
        cursor: 'pointer'
    },
    titAll: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '12px',
        position: 'relative',
        overFlow: 'hidden',
        height: "100%"
    },
});

class ChangeColor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            colors: this.props.data.tags ? this.props.data.tags.map((value) => {
                return value.color
            }) : {},
            contents: this.props.data.tags.map((value) => {
                return value.content
            }),
            isFetching: 0,
            open: false
        }
    }

    changeColor = (color) => {
        const {dispatch} = this.props;
        let newtags = JSON.parse(JSON.stringify(this.props.data.tags))
        newtags.forEach(t => {
            if (t.color === color) {
                t.is_selected = 1
            } else {
                t.is_selected = 0
            }
        })
        this.props.dispatch({
            type:'SET_LOADING_TRUE'
        })
        axios.post(UPDATE_TAGS, {
            tags: newtags
        }).then(res => {

            this.props.dispatch({
                type:'SET_LOADING_FALSE'
            })
            if (res.data){
                dispatch({
                    type: 'SET_CHOOSE_COLOR',
                    tags: newtags,
                    uuid: this.props.data.uuid
                })
            }

        }).catch(e => {
            console.log(e)
            this.props.dispatch({
                type:'SET_LOADING_FALSE'
            })
        }).finally(
            this.handleClickAway()
        )

    };

    getContent = (content) => {
        const {dispatch} = this.props;
        dispatch({
            type: CONTENT,
            content,
            id: this.props.id
        })
    };
    setIsOpen = (option) => {
        this.setState({
            open: option
        });
    }
    handleClickAway = () => {
        this.setState({
            open: false,
        });
    };
    onHoverEnter = (id) => {
        this.setState({
            isFetching: id
        })
    }

    render() {
        const {classes, fontSize, data, loading} = this.props
        let backgroundColor = ''
        if (!_.isEmpty(data.tags)) {
            backgroundColor = data.tags[0].color
            data.tags.forEach(t => {
                if (t.is_selected) {
                    backgroundColor = t.color
                }
            })
        } else {
            backgroundColor = 'gray'
        }

        return (
            <Tooltip
                position="bottom-end"
                animation="scale"
                arrow="true"
                arrowSize="big"
                theme="light"
                trigger="click focus"
                onRequestClose={this.handleClickAway}
                interactive
                style={{width: '100%'}}
                open={this.state.open}
                html={(
                    <div>
                        <LoadingOverlay>
                            <Loader loading={loading} text={''}/>
                            <Fab
                                className={classes.buttonClose}
                                onClick={this.handleClickAway}>
                                <Close style={{fontSize: 16}}/>
                            </Fab>
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                <div style={{minWidth: 200, position: 'relative', overflow: 'hidden'}}>
                                    <Paper elevation={0}
                                           className={classes.titAll}>
                                        <Typography variant="subtitle2" style={{float: 'left'}}>
                                            All
                                        </Typography>
                                        <Typography component="p"
                                                    style={{float: 'right', color: '#28afb4', fontSize: fontSize}}>
                                            Setting Category
                                        </Typography>
                                    </Paper>
                                    <Grid container item xs={12}>
                                        {this.state.colors.map((value, index) => {
                                            return (
                                                <Button
                                                    size="small"
                                                    className={classes.button}
                                                    key={index}
                                                    style={{
                                                        borderColor: value,
                                                        padding: 2,
                                                        lineHeight: '100%',
                                                        fontSize: fontSize
                                                    }}
                                                    onClick={() => this.changeColor(value)}>
                                                    AAA
                                                </Button>
                                            )
                                        })}
                                    </Grid>
                                    <Grid item xs={12}
                                          className={classes.borderTopLeft}
                                          style={{borderColor: `${backgroundColor}`}}>
                                        {this.state.contents.map((content) => {
                                            return (
                                                <Grid
                                                    key={content}
                                                    item
                                                    className={classes.listBox}
                                                    style={{
                                                        padding: 0, width: '25%', height: '36px',
                                                        borderColor: `${backgroundColor}`,
                                                        backgroundColor: this.state.isFetching === content ? `${backgroundColor}` : `#ffffff`
                                                    }}
                                                    onMouseEnter={() => this.onHoverEnter(content) + this.getContent(content)}
                                                >
                                                    <TagButton
                                                        fontSize={fontSize}
                                                        className={classes.listText}
                                                        content={content}
                                                    />
                                                </Grid>
                                            )
                                        })
                                        }
                                        <Grid
                                            className={classes.listBox}
                                            key={"category_plus_button"}
                                            item
                                            style={{
                                                float: 'left',
                                                padding: 0,
                                                width: '25%',
                                                height: '36px',
                                                borderColor: `${backgroundColor}`,
                                            }}
                                        >
                                            <TagButton
                                                fontSize={fontSize}
                                                content={'+'}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </ClickAwayListener>
                        </LoadingOverlay>
                    </div>
                )}
            >

                <Checkbox
                    style={{padding: 6}}
                    checked={false}
                    onClick={() => this.setIsOpen(true)}
                    color="default"
                    icon={<img
                        src="../img/icon05.png"
                        alt=""
                        style={{position: 'relative'}}
                    />}
                />
            </Tooltip>
        );
    }
}

function TagButton({content, fontSize, className}) {
    return (
        <Button
            className={className}
            style={{
                fontSize: fontSize,
                width: '100%',
                lineHeight: '100%'
            }}>{content}
        </Button>
    )
}

export default connect(
    (state) => ({
        color: state.color,
        loading: state.loading
    })
)(withStyles(styles)(ChangeColor))
