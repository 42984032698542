import agent from './agent';
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
} from './constants/actionTypes';
import Auth from './auth';

var initAuth = store => next => action => { 
  if (Auth.Core.initialState.auth.isLogin === true) {
    agent.setToken(Auth.Core.initialState.auth.token);
  }
  next(action);
} 
const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ 
      type: ASYNC_START,
       subtype: action.type
    });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        console.log('ERROR', error);
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.type === LOGIN) {
    if (!action.error) {
      agent.setToken(Auth.Core.token);
    }
  } else if (action.type === LOGOUT) {
    Auth.Core.logOut();
  }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}


export {initAuth, promiseMiddleware, localStorageMiddleware }
