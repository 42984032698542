import {getParamToken, removeURLParameter,} from "./helper/utility";
import {COOKIE_GENIAM_REFRESH_TOKEN_KEY, getCookie, removeCookie, setCookie} from "./common/cookies";

let Auth = {};


(function (_) {
    _.Core = {
        setting: {},
        initialState: {
            service_id: 2,
            course_id: 1,
            auth: {
                isLogin: false,
                token: null,
                user: null,
                refreshToken: null,
                test: null
            },
            global: _
        },
        keyToken: "token",
        keyQuery: "token",
        keyUser: "user",
        keyRefreshToken: "refreshToken",
        token: null,
        refreshToken: null,
        isLogOut: false,
        isFooter: true,
        setLocalStorage: function (key, isToken) {
            if (isToken !== undefined && isToken !== null) {
                window.localStorage.setItem(key, isToken);
            }
        },
        setSessionStorage: function (key, isToken) {
            if (isToken !== undefined && isToken !== null) {
                window.sessionStorage.setItem(key, isToken);
            }
        },
        getToken: function (key) {
            return window.localStorage.getItem(key);
        },
        getSession: function (key) {
            return window.sessionStorage.getItem(key);
        },
        setToken: function (key, setting) {
            Object.assign(this.setting, setting);
            var token = getParamToken("token");
            var refreshToken = getParamToken("refreshToken");

            var isToken = this.getToken(key);
            if (token !== undefined && token !== "") {
                isToken = token;
            }

            var isRefreshToken = getCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
            if (refreshToken !== undefined && refreshToken !== "") {
                isRefreshToken = refreshToken;
            }

            this.token = isToken;
            this.refreshToken = isRefreshToken;
            this.setLocalStorage(key, isToken);
            this.setLocalStorage(this.keyRefreshToken, isRefreshToken);
            setCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY, isRefreshToken)
            var testToken = getParamToken("test");
            var isTest = this.getToken("test");
            if (testToken !== undefined && testToken !== null) {
                isTest = testToken;
            }
            this.setLocalStorage("test", isTest);

            return this;
        },
        auth: function (callback) {
            var uri = window.location.href;
            uri = removeURLParameter("token", uri);
            uri = removeURLParameter("refreshToken", uri);
            window.history.pushState({}, document.title, uri);

            var _self = this;
            var token = this.token;
            if ((typeof token === "string")
                && (token)
                && (token.length !== null)
            ) {
                _self.initialState.auth.user = null;
                _self.initialState.auth.token = null;
                _self.initialState.auth.isLogin = false;
                fetch("https://apis.geniam.com/getuserdata", {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'GET'
                })
                    .then(res => res.json())
                    .then(json => {
                        if (Object.keys(json).length > 0) {
                            if ((typeof json.user_id !== undefined) && (typeof json.user_id === "string")) {
                                var userId = json.user_id.length;
                                if (userId > 0) {
                                    var user = JSON.stringify(json);
                                    _self.setSessionStorage(_.Core.keyUser, user);
                                    _self.initialState.auth.user = json;
                                    _self.initialState.auth.token = _self.token;
                                    _self.initialState.auth.refreshToken = _self.refreshToken;
                                    _self.initialState.auth.isLogin = true;
                                }
                            }
                        }
                    }).then(() => {
                    if (!_self.initialState.auth.isLogin) {
                        _self.refresh(callback);
                    } else {
                        callback();
                    }
                });
            } else {
                callback();
            }
        },
        refresh: function (callback) {
            var _self = this;
            var token = _self.token;
            var refreshToken = _self.refreshToken;
            if ((typeof refreshToken === "string")
                && (refreshToken)
                && (refreshToken.length !== "")) {
                fetch("https://apis.geniam.com/refresh", {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify({
                        refreshToken: _self.refreshToken
                    })
                }).then(res => res.json())
                    .then(json => {
                        if (Object.keys(json).length > 0) {
                            if ((typeof json.accessToken !== undefined)
                                && (json.accessToken)
                                && (typeof json.accessToken === "string")) {
                                _self.setLocalStorage(_self.keyToken, json.accessToken);
                                _self.initialState.auth.isLogin = true;
                                _self.initialState.auth.token = json.accessToken;
                            }
                        }
                    })
                    .then(()=>{
                        if (!_self.initialState.auth.isLogin) {
                            _self.logOut();
                        } else {
                            window.location = window.location.href;
                        }
                    });
            } else {
                _self.logOut();
            }
        },
        logOut: function () {
            var token = this.token;

            fetch("https://apis.geniam.com/logout", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }).then(res => {
                window.sessionStorage.removeItem("user");
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("refreshToken");
                removeCookie(COOKIE_GENIAM_REFRESH_TOKEN_KEY)
                return res;
            }).then(result => {
                window.location = window.location.href;
            });
        }
    }

})(Auth);

export default Auth