// const REST_API_URL = "/api/v1";
const apiUrl = {
  LOGIN: `https://myaccount.geniam.com/login`,
  SIGNUP: `https://myaccount.geniam.com/register`,
  SWITCH_ACCOUNT: `https://myaccount.geniam.com/login/select-account`,
  MY_ACCOUNT: `https://myaccount.geniam.com/account`,
  INSIGHT_MAP: `https://insightmind.geniam.com:3003/api/get-insight-info`,
  UPDATE_TASK: `https://insightmind.geniam.com:3003/api/update-insight-task`
};
export default apiUrl

export let API_PREFIX;
export let REDIRECT_URL;
export let API_GRAPH;
export let API_WS;
export let INNER_TOURS;
export let API_TOP
export let API_TASK
export let API_GENIAM
switch (process.env.NODE_ENV) {
    case 'development':
    case 'dev':
        API_PREFIX = 'http://localhost:5003';
        API_GRAPH = 'https://enecolor-stg.geniam.com/api/v1/users/graph';
        REDIRECT_URL = 'http://localhost:4100/redirect';
        INNER_TOURS = 'http://localhost:4100'
        API_WS = 'http://localhost:5003'
        API_TOP = 'http://localhost:3333'
        API_TASK ='http://localhost:9999'
        API_GENIAM = 'https://apis.geniam.com'
        break;
    default:
        API_PREFIX = 'https://apis.metamors.com';
        API_GRAPH = 'https://enecolor-stg.geniam.com/api/v1/users/graph';
        REDIRECT_URL='https://innertours.geniam.com/redirect';
        INNER_TOURS='https://innertours.geniam.com';
        API_WS = 'https://apis.metamors.com'
        API_TOP = 'https://api-top.geniam.com'
        API_TASK = 'https://apis-tasks.geniam.com'
        API_GENIAM = 'https://apis.geniam.com'
}

export  const GET_SCHEDULES = API_PREFIX + '/innertours/schedules'
export  const  GET_METAMORS = API_PREFIX +'/admin/metamor'
export  const BUY_SCHEDULE = API_PREFIX + '/innertours/register'
export  const GET_COURSES = API_PREFIX +'/admin/courses-service'
export  const GET_METAMOR_RANK = API_PREFIX + '/services/metamor_rank'
export  const GET_CONTENT_SKILL = API_PREFIX + '/services/content'
export  const GET_SCHEDULES_METARMOR = API_PREFIX + '/services/metamor_schedules'
export  const GET_SCHEDULES_CONTENTS = API_PREFIX + '/services/content_schedules'
export  const GET_KOMA_LIST = API_PREFIX + '/komalist'
export  const CANCEL_KOMA = API_PREFIX + '/koma-cancel'
export  const CALENDAR_CHANGE_TIME = INNER_TOURS + '/calendar?type=metamor&'
export  const CALENDAR_SAME_SCHEDULES = INNER_TOURS + '/calendar'
export  const STAR_UPDATE = API_TOP + '/update-star'
export  const PIN_UPDATE = API_TOP + '/update-pin'
export  const GET_INSIGHTMAP = API_TOP + '/getinsightmap'
export  const GET_TASK_LIST = API_TASK + '/getTask'
export  const UPDATE_TASK = API_TASK + '/update-process'
export  const UPDATE_TAGS = API_TOP +'/update-tags'
export  const UPDATE_IDENTITIES = API_TOP + '/update-identities'
export const REFRESH_TOKEN_URL = API_GENIAM + "/refresh"