import React from 'react'
import {withStyles, Tabs, Tab, AppBar, Grid} from '@material-ui/core'
import { connect } from 'react-redux'
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 15,
        flexWrap: 'wrap',
        width: '100%'
    },
    tabs: {
        backgroundColor: "#3a3a3a",
        borderRadius: "6px",
        color: "#939393",
        maxHeight: 35,
        minHeight: 0
    },
    tab: {
        borderRight: "1px solid #5b5b5b",
        color: "#fff",
        fontSize: "14px",
        textAlign: "center",
        minHeight: 35,
        lineHeight: 0,
    },
    indicator: {
        backgroundColor: '#3a3a3a',
    },
});

class NavApp extends React.Component {

    handleChange = (event, value) => {
        this.props.dispatch({
            type: 'SET_TAB',
            tab:value
        })
    }
    render() {
        const {classes} = this.props;
        return (
            <Grid item xs={12}>
                <div className={classes.root}>
                    <AppBar style={{borderRadius: "6px"}} position="static" color="default">
                        <Tabs
                            value={this.props.tab}
                            onChange={this.handleChange}
                            className={classes.tabs}
                            fullWidth
                            classes={{
                                indicator: classes.indicator
                            }}>
                        >
                            <Tab className={classes.tab} value={1} label="ピンどめ"/>
                            <Tab className={classes.tab} value={2} label="お気に入り"/>
                            <Tab className={classes.tab} value={3} label="活動記録"/>
                        </Tabs>
                    </AppBar>
                </div>
            </Grid>
        );
    }
}

export default connect(
    (state)=>({
        tab: state.tab
    })
)(withStyles(styles)(NavApp))
