import React, {Component} from 'react'
import {Grid, withStyles} from '@material-ui/core'
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormGroup from '@material-ui/core/FormGroup'
import Paper from "@material-ui/core/Paper/Paper"
import axios from 'axios';
import {connect} from 'react-redux'
import _ from 'lodash'
import { UPDATE_TASK } from "../../config/apiUrl";
import {Loader, LoadingOverlay} from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
const styles = {
    root: {
        backgroundColor:"#f7f7f7",
        border: "1px solid #b3b3b3",
        borderRadius:"0",
        padding: 15,
    },
}
class CheckBoxs extends Component {
    state={
        dataTask : this.props.data.task
    }


    handleChange = (task,process) => event => {
        this.props.dispatch({
            type:'SET_LOADING_TRUE'
        })
        axios.post(UPDATE_TASK, {
            uuid: task.uuid,
            process:task.process + process,
        }).then(res => {
            console.log(res.data)
            this.props.dispatch({
                type:'SET_LOADING_FALSE'
            })
            this.props.dispatch({
                type:'UPDATE_TASK_PROCESS',
                uuid:task.uuid,
                task: res.data
            })
        }).catch(e=>{
            console.log(e)
            this.props.dispatch({
                type:'SET_LOADING_FALSE'
            })
        })
    };

    render() {
        const { classes ,data, tasks ,loading } = this.props;
        let backgroundColor = ''
        if(!_.isEmpty(data.tags)){
            backgroundColor = data.tags[0].color
            data.tags.forEach(t=>{
                if (t.is_selected) {
                    backgroundColor = t.color
                }
            })
        }else {
            backgroundColor = 'gray'
        }
        let dataTask = []
        if(!_.isEmpty(tasks)){
            tasks.forEach(t=>{
                if (t.insightmap_uuid === data.uuid) {
                    dataTask = _.concat(dataTask,t)
                }
            })
        }
        return (
            <Grid item xs={12}>
                <LoadingOverlay>
                    <Loader loading={loading} text={''}/>
                <Paper classes={{root:classes.root}} elevation={0}>
                    <FormGroup>
                        {!_.isEmpty(dataTask)?dataTask.map((value,index) => {
                            let checkBox = ''
                            let style = { position: 'relative',borderRadius:4,backgroundColor: `${backgroundColor}` }
                            switch (+value.process) {
                                case 0 :
                                    checkBox = <img
                                        src="../img/check.png"
                                        alt=""
                                        style={style}/>
                                    break
                                case 25 :
                                    checkBox = <img
                                        src="../img/check25.png"
                                        alt=""
                                        style={style}/>
                                    break
                                case 50 :
                                    checkBox = <img
                                        src="../img/check50.png"
                                        alt=""
                                        style={style}/>
                                    break
                                case 75 :
                                    checkBox = <img
                                        src="../img/check75.png"
                                        alt=""
                                        style={style}/>
                                    break
                                case 100 :
                                    checkBox = <img
                                        src="../img/check100.png"
                                        alt=""
                                        style={style}/>
                                    break
                                default :
                                    value.process = 0
                            }
                            let process = Number(value.process)===100 ? -100 : 25
                            return (
                                <FormControlLabel
                                    onChange={this.handleChange(value,process)}
                                    key={index} style={{marginLeft:"3px"}}
                                    control={
                                        <Checkbox
                                            checked={false}
                                            icon= {checkBox}
                                            value="checkedC"
                                            style={{padding:4,marginRight:5}}
                                        />
                                    } label={value.content}/>
                            )
                        }):''}
                    </FormGroup>
                </Paper>
                </LoadingOverlay>
            </Grid>
        );
    }
}

export default connect(
    (state)=>({
        color:state.color,
        tasks: state.tasks,
        loading:state.loading
    })
) (withStyles(styles)(CheckBoxs));