import React, {Component} from "react";
import {Grid, withStyles, Button} from "@material-ui/core";
import Calendar from "../Calendar/Calendar";
import TimeFrame from "../TimeFrame/TimeFrame";
import {connect} from 'react-redux';
import qs from 'query-string'
import {CALENDAR_CHANGE_TIME} from "../../config/apiUrl";

const styles = theme => ({
    root: {
        flexGrow: 1,
        borderRadius: 10,
        ...theme.typography.body2,
        textAlign: 'center',
        minHeight: 120
    },
    title: {
        ...theme.typography.heading,
        fontWeight: 'bold',
        height: 25,
        paddingTop: 4,
    },
    calendar: {
        backgroundColor: '#fff',
        borderBottomLeftRadius: theme.spacing.unit * 1,
        borderBottomRightRadius: theme.spacing.unit * 1,
        width: 85,
        margin: 'auto'
    },
    colorText: {
        color: '#FFF'
    },
    linkFooter: {
        paddingTop: 8,
        fontSize: '0.6875rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.59rem',
        },
    }
})

class KomaRight extends Component {

    /**
     *
     * @param value
     * @returns {string}
     */
    upBg_Color = (value) => {
        let result = '#8ac77a';
        if (value === '予約済み') {
            result = '#7a97c7'
        }
        if (value === '完了') {
            result = '#818181'
        }
        return result;
    };
    /**
     * @param title
     * @param linkCancel
     * @param linkCC
     * @param linkRelated
     * @returns {*}
     * @constructor
     */
    canCel = () => {
        this.props.dispatch({
            type: 'SET_SELECTING_KOMA',
            koma: this.props.data
        })
        this.props.dispatch({
            type: 'SET_OPEN_CANCEL_DIALOG_TRUE'
        })
    }
    LinkChangeTime = () => {
        console.log('change time')
        if (this.props.data.schedules[0].service_name === 'innertours') {
            let querry = qs.stringify({
                uuid: this.props.data.schedules[0].metamor.uuid,
                rank: this.props.data.schedules[0].metamor.profile.rank,
                contents: 'insightmap',
                product_id: this.props.data.product_id,
                minutes: this.props.data.coma * 50,
                change_from: this.props.data.uuid
            }, {sort: false, encode: false})
            window.open(CALENDAR_CHANGE_TIME + querry, '_blank')
        }

    }
    SameSchedule = () => {
        if (this.props.data.schedules[0].service_name === 'innertours') {
            let querry = qs.stringify({
                uuid: this.props.data.schedules[0].metamor.uuid,
                rank: this.props.data.schedules[0].metamor.profile.rank,
                contents: 'insightmap',
                product_id: this.props.data.product_id,
                minutes: this.props.data.coma * 50,
            }, {sort: false, encode: false})
            window.open(CALENDAR_CHANGE_TIME + querry, '_blank')
        }
    }
    BuyNow = () =>{
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        let url = 'https://cart2.geniam.com/?'
        window.open(url +
            qs.stringify({
                add: this.props.data.product_id,
                selected_time: this.props.data.selected_time,
                register_uuid: this.props.data.uuid,
                token,
                refreshToken
            }, {sort: false, encode: false})
        )

    }
    Link_footer = (title, linkCancel, linkCC, linkRelated) => {
        let result = null;
        const {classes} = this.props;
        if (title === '予約済み') {
            result = (
                <Grid container item xs={12} className={classes.linkFooter}>
                    <Grid item xs={7}>
                        <Button style={{color: '#29abe2'}} onClick={this.canCel}>
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item xs={5} style={{textAlign: 'right'}}>
                        <Button style={{color: '#29abe2'}} onClick={this.LinkChangeTime}>
                            日程変更
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        if (title === '完了') {
            result = (
                <Grid container item xs={12} className={classes.linkFooter} justify={'flex-end'}>
                    {this.props.NotBuy &&
                    <Grid item xs={5}>
                        <Button style={{color: '#29abe2'}} onClick={this.BuyNow}>
                            Buy Now
                        </Button>
                    </Grid>
                    }

                    <Grid item xs={7}>
                        <Button onClick={this.SameSchedule} style={{color: '#29abe2'}}>
                            関連ファイル
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        return result;
    }

    render() {
        const {
            classes,
            calendarTitle = '予約する',
            month,
            day,
            hourStart,
            hourEnd,
            linkCancel = '#',
            linkCC = '#',
            linkRelated = '#'
        } = this.props;
        let title = (!calendarTitle) ? '予約する' : calendarTitle;
        return (
            <Grid item xs={12}>
                {(title !== '予約する') ?
                    <Grid container item xs={12} className={classes.root}
                          style={{backgroundColor: this.upBg_Color(title)}}>
                        <Grid item xs={12}
                              className={classes.title + ' ' + classes.colorText}>{title}</Grid>
                        <Calendar
                            month={month}
                            day={day}
                            classCalendar={classes.calendar}
                        />
                        <TimeFrame
                            hourStart={hourStart}
                            hourEnd={hourEnd}
                            classTimeFrame={classes.colorText}
                        />
                    </Grid> :
                    <Grid container item xs={12} className={classes.root}
                          style={{backgroundColor: this.upBg_Color(title)}}>
                        <Grid item xs={12} className={classes.title + ' ' + classes.colorText}
                              style={{margin: 'auto', fontSize: '1.333rem',}}>{title}</Grid>
                    </Grid>
                }
                {this.Link_footer(title, linkCancel, linkCC, linkRelated)}
            </Grid>
        )
    }
}

export default connect(
    (state) => ({
        openCancelDialog: state.openCancelDialog
    })
)(withStyles(styles)(KomaRight));