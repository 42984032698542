import React, {Component} from 'react';
import {Grid, withStyles} from '@material-ui/core';

const styles = theme => ({
    root: {
        borderRadius: 10,
        ...theme.typography.body2,
        color: '#ffffff',
        minWidth: 70,
        textAlign: 'center',
        fontSize: '0.75rem',
    }
})

class SeviceName extends Component {
    upColorAndServiceName = (service) => {
        let color = '#3a3a3a'
        let serviceName = service
        if (service === 'innertours') {
            serviceName = 'Inner Tours'
            color = '#29abe2'
        }
        if (service === 'enecolor') {
            color = '#f5cf23'
        }
        return {color, serviceName}
    }

    render() {
        const {classes, serviceName} = this.props;
        let service_name = !serviceName ? 'No service' : serviceName
        const color = this.upColorAndServiceName(service_name).color
        const name = this.upColorAndServiceName(service_name).serviceName
        return (
            <Grid item className={classes.root} style={{backgroundColor: color}}>
                {name}
            </Grid>
        );
    }
}

export default withStyles(styles)(SeviceName)