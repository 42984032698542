import React from 'react'
import {Grid} from "@material-ui/core"
import {RssFeed} from "@material-ui/icons"
import Grids from "../AdvancedGrid/AdvancedGrid"
import Topic from "../Topic/Topic"
import Advertisement from "../Advertisement/Advertisement"
import StickyBox from "react-sticky-box"
import {isMobileOnly} from "react-device-detect"

export default function RightMainApp() {
    let xs = 6
    if (isMobileOnly) {
        xs = 24
    }
    return (
        <Grids item xs={xs} style={{maxWidth: 750}}>
            <StickyBox offsetBottom={20} offsetTop={70}>
                <Grid style={{padding: 8}}>
                    <Grid container spacing={16}>
                        <Topic icon={<RssFeed/>} title={'ニュース'}/>
                        <Grid item>
                            <Advertisement
                                img={process.env.PUBLIC_URL + '/img/img01.png'}
                                title={'《期間限定》キャンペーン'}
                                content={'昨今「アウフヘーベン」という言葉が、よく聞かれます。 日本語訳は、「揚棄」や「止揚」です。 意味は、「矛盾するものを更に高い段階で統一し解決すること。」とされていま．．．'}
                                linkTo={'#'}
                            />
                            <Advertisement
                                img={process.env.PUBLIC_URL + '/img/img02.png'}
                                title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                content={'昨今「アウフヘーベン」という言葉が、よく聞かれます。 日本語訳は、「揚棄」や「止揚」です。 意味は、「矛盾するものを更に高い段階で統一し解決すること。」とされていま．．．'}
                                linkTo={'#'}
                            />
                            <Grid container item xs={12} style={{margin: '70px 0px'}}>
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                    linkTo={'#'}
                                />
                                <Advertisement
                                    title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                    linkTo={'#'}
                                />
                            </Grid>
                            <Advertisement
                                title={'《期間限定》キャンペーン'}
                                img={process.env.PUBLIC_URL + '/img/img01.png'}
                                linkTo={'#'}
                            />
                            <Advertisement
                                title={'《期間限定》キャンペーン実施中：体験受講歓迎冬季限定'}
                                img={process.env.PUBLIC_URL + '/img/img02.png'}
                                linkTo={'#'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </StickyBox>
        </Grids>
    )
}