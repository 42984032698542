import React from "react"
import {Grid, CardMedia, withStyles} from "@material-ui/core"

const styles = theme => ({
    card: {
        border: '1px solid rgb(179, 179, 179)',
        borderBottom:'none',
        height: 300,
    },
    cardMedia: {
        width: '100%',
        backgroundSize:'contain'
    },
    titleMedia: {
        border: '1px solid rgb(179, 179, 179)',
        ...theme.typography.h6,
        fontWeight: 'bold',
        fontSize: '0.875rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8125rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            paddingLeft: 5
        },
    }
})
class ContentMedia extends React.Component {
    render() {
        const {classes, imgMedia, titleMedia, title} = this.props
        return (
            <Grid container item xs={12} spacing={16} style={{margin: 0}}>
                <Grid container item xs={12} className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={imgMedia}
                        title={title}
                    />
                </Grid>
                <Grid item xs={12} className={classes.titleMedia}>
                    {titleMedia}
                </Grid>

            </Grid>
        )
    }
}
export default withStyles(styles)(ContentMedia)