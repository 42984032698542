import React, {Component} from 'react';
import {Grid, CardMedia, withStyles} from '@material-ui/core';
import {Link} from "react-router-dom";

const styles = theme => ({
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
    title: {
        ...theme.typography.subheading,
        textAlign: 'left',
        fontSize: '0.8125rem',
        padding: '10px 0px'
    }
});

class MediaBox extends Component {

    render() {
        const {classes, title, img, classTitle, linkTo, classLink, classMediaBox} = this.props;
        return (
            <Grid item xs={12} className={classMediaBox} >
                {!img ? null :
                    <CardMedia
                        component="img"
                        alt={title}
                        className={classes.media}
                        image={img}
                        title={title}
                    />
                }
                <div className={classes.title + ' ' + classTitle}>
                    {!linkTo ? title : <Link to={linkTo} className={classLink}>{title}</Link>}
                </div>
            </Grid>
        );
    }
}

export default withStyles(styles)(MediaBox);