import React, {Component} from 'react'
import {Grid, withStyles, IconButton} from '@material-ui/core'
import {Share} from "@material-ui/icons"
import StarRatingComponent from 'react-star-rating-component'
import {connect} from 'react-redux'
import 'react-tippy/dist/tippy.css'
import {isMobileOnly} from "react-device-detect"
import CheckUsers from "./CheckUsers";
import ChangeColor from "./ChangeColor";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const styles = theme => ({

    share: {
        ...theme.typography.body2,
        color: '#7d7d7d',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.75rem'
        },
    },
    buttonShare: {
        padding:0,
        width: 35,
        height:35,
        [theme.breakpoints.down("sm")]: {
            width: 24,
            height:24,
            padding: 0,
        }
    },
    StarRating: {
        fontSize: 24
    },
    imageIcon: {
        position: 'relative',
    }
});

class HandleMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating_empty_initial: 0,
            checkedF: false,
        }
    }

    onStarClickEmptyInitial(nextValue, prevValue, name) {
        console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
        this.setState({rating_empty_initial: nextValue});
        this.props.dispatch({
            type:'SET_SELECTING_INSIGHTMAP',
            insightmap: {...this.props.data,star:nextValue}
        })
        this.props.dispatch({
            type:'SET_OPEN_STAR_DIALOG_TRUE'
        })
    }
    handleChange =  event => {
        this.props.dispatch({
            type:'SET_SELECTING_INSIGHTMAP',
            insightmap: {...this.props.data,is_pin:event.target.checked}
        })
        this.props.dispatch({
            type:'SET_OPEN_PIN_DIALOG_TRUE'
        })
    };

    render() {
        const {classes, innerWidth,data} = this.props
        let size = (innerWidth === 'xs' && isMobileOnly) ? 24 : 26
        let fontSize = (innerWidth === 'xs') ? '3vw' : 12
        return (
            <Grid container item xs={12}>
                <Grid container item xs={4} alignItems={"center"} className={classes.share}>
                    <IconButton aria-label="Delete" className={classes.buttonShare}>
                        <Share/>
                    </IconButton>
                    シェアする
                </Grid>
                <Grid container item xs={8} alignItems={"center"} justify={"flex-end"}>
                    <StarRatingComponent
                        name="rate2"
                        starCount={5}
                        value={this.props.data.star}
                        onStarClick={this.onStarClickEmptyInitial.bind(this)}
                        emptyStarColor="#ABABAB"
                    />
                    <style type="text/css">
                        {
                            `.dv-star-rating{
                                font-size: ${size}px;
                                margin-right: 6px;
                            }`
                        }
                    </style>
                    <div>
                        <Checkbox
                            style={{padding:6}}
                            checked={this.props.data.is_pin}
                            onChange={this.handleChange}
                            color='default'
                            value="checkedF"
                            icon={ <img
                                src="../img/icon04-2.png"
                                alt=""
                                style={{position: 'relative' }}
                            />}
                            checkedIcon={ <img
                                src="../img/icon04.png"
                                alt=""
                                style={{position: 'relative' }}
                            />}
                        />
                        <ChangeColor data={data} fontSize={fontSize} id={this.props.id}/>
                        <CheckUsers innerWidth={size} fontSize={fontSize} identities={data.identities} uuid={data.uuid}/>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    (state) => ({
        color: state.color
    })
)(withStyles(styles)(HandleMain))