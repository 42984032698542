import React from 'react'
import {AppBar, Toolbar, withStyles, Grid} from '@material-ui/core'
import favicon from './favicon.ico'
import {isMobileOnly} from "react-device-detect"

const styles = theme => ({
    footer: {
        flexGrow: 1,
        fontSize: '0.75rem',
        textAlign: 'center',
        paddingTop: 10,
        [theme.breakpoints.down('xs')]:{
            fontSize: '2.37vw',
        }
    },
    appBar: {
        backgroundColor: '#3A3A3A',
        padding: '10px 0px',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            color: '#b1aaaa',
            transition: 'none'
        }
    }
})

class Footer extends React.Component {
    render() {
        const {classes} = this.props
        let size = isMobileOnly ? 320 : 767
        return (
            <Grid container className={classes.footer} style={{minWidth: size}}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar style={{padding:8}}>
                        <Grid container justify={"center"} spacing={8}>
                            <Grid container item xs={12} style={{maxWidth: 600 }} spacing-sm-8='true'>
                                <Grid item xs={3}>
                                    <a
                                        href="https://invenstor.co.jp/"
                                        className={classes.link}
                                        target="_blank"
                                        rel='noopener noreferrer'
                                    >
                                        株式会社 Invenstor
                                    </a>
                                </Grid>
                                <Grid item xs={2}>
                                    <a
                                        href="https://myaccount.geniam.com/account/terms"
                                        className={classes.link}
                                        target="_blank"
                                        rel='noopener noreferrer'
                                    >
                                        利用規約
                                    </a>
                                </Grid>
                                <Grid item xs={3}>
                                    <a
                                        href="https://myaccount.geniam.com/account/privacy"
                                        className={classes.link}
                                        target="_blank"
                                        rel='noopener noreferrer'
                                    >
                                        プライバシーポリシー
                                    </a>
                                </Grid>
                                <Grid item xs={4}>
                                    <a
                                        href="https://myaccount.geniam.com/account/patent-commerce"
                                        className={classes.link}
                                        target="_blank"
                                        rel='noopener noreferrer'
                                    >
                                        特定商取引法に基づく表記
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justify={"center"} spacing={8}>
                                <Grid container justify={"center"} style={{paddingTop: 8}}>
                                    <Grid item xs={"auto"}>
                                        <img src={favicon} alt="favicon" width={35} height={35}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={"auto"}>
                                    Geniam
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justify={"center"}>
                                <Grid item xs={"auto"}>
                                    Copyright (C) 2018 Invenstor .inc All Rights Reserved..
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Grid>
        );
    }
}

export default withStyles(styles)(Footer)
