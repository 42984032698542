import React from 'react'
import PropTypes from 'prop-types';
import {Grid, withStyles, withWidth} from '@material-ui/core'
import {connect} from 'react-redux'
import ContentTitle from "./ContentTitle"
import Avatars from "../Avatars/Avatars"
import CheckBox from "./CheckBox"
import HandleMain from "./HandleMain"
import ContentMedia from "./ContentMedia"
import {TranferDateTime} from "../../helper/utility"
import ObjectPath from 'object-path'
import Typography from "@material-ui/core/es/Typography/Typography";
import Paper from "@material-ui/core/es/Paper/Paper";
import Parser from 'html-react-parser';
import _ from "lodash";
const styles = theme=> ({
    root: {
        backgroundColor: "#fff",
        borderRadius: 15,
        paddingTop: '4px !important',
        paddingBottom: '4px !important',
        margin: 0,
        marginBottom: 12

    },
    memo:{
        fontSize:'1rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '0.875rem'
        },
    }
})

class ContentMain extends React.Component {
    render() {
        const {
            classes,
            avatar = process.env.PUBLIC_URL + '/img/logo-insight.png',
            title = 'Insight Mind',
            width,
            data,
        } = this.props
        let backgroundColor = ''
        if(!_.isEmpty(data.tags)){
            backgroundColor = data.tags[0].color
            data.tags.forEach(t=>{
                if (t.is_selected) {
                    backgroundColor = t.color
                }
            })
        }else {
             backgroundColor = 'gray'
        }

        const text = data.memo
        const HTMPParser = Parser(text)
        return (
            <Grid
                container
                item
                xs={12}
                spacing={16}
                className={classes.root}
                style={{border: `5px solid ${backgroundColor}`}}
            >
                <ContentTitle
                    src={avatar}
                    title={title}
                    date={TranferDateTime(data.reg_date)}
                    id={this.props.id}
                    data={data}
                />
                <Grid item xs={12} className={classes.memo} >
                    <Paper  elevation={0}>
                        <Typography component="p">
                            {HTMPParser}
                        </Typography>
                    </Paper>
                </Grid>
                <ContentMedia
                    imgMedia={this.props.data.image}
                    title={title}
                    titleMedia={data.title}
                />
                <Avatars
                    avatar={ObjectPath.get(this.props.auth, "auth.user.avatar")}
                    name={`${ObjectPath.get(this.props.auth, "auth.user.last_name")} ${ObjectPath.get(this.props.auth, "auth.user.first_name")}`}
                    avatarList={data.user_share}
                    innerWidth={width}
                    uuid = {data.metamor_uuid}
                    data = {data.usershares}
                />
                <HandleMain id={this.props.id} innerWidth={width} data={data}/>
                <CheckBox id={this.props.id} data={data}/>
            </Grid>
        );

    }
}

ContentMain.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(
    (state) => ({
        color: state.color,
        content:state.content
    })
)(withWidth()(withStyles(styles)(ContentMain)))
