import _ from 'lodash'

export default (state =[], action)=>{
    switch (action.type) {
        case 'SET_USER_SHARE':
            let nextState = [...state]
            nextState = _.concat(nextState,action.user_share )
            nextState = _.uniqBy(nextState,'user_id')
            return nextState
        default:
            return state
    }
}