import React, {Component} from 'react'
import MenuAppBar from "../components/Application/MenuAppBar/MenuAppBar"
import Content from "../Page/Content"
import Footer from "../components/Application/Footer/Footer";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import apiUrl from "../config/apiUrl"

const styles = {
    root: {
        flexGrow: 1,
        marginTop: 50,
    },
};

class Home extends Component {
    state = {
        completed: 0,
    };
    componentDidMount() {
        if(!this.props.auth.auth.isLogin && window.location.href.indexOf('?is_dev') !== -1) {
            this.timer = setInterval(this.progress, 100);
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    progress = () => {
        const { completed } = this.state;
        if (completed > 90) {
            let url = apiUrl.LOGIN + '?redirect_url=' + window.location.href;
            clearInterval(this.timer);
            window.location.assign(url);
        } else {
            const diff = (Math.random() * 10);
            this.setState({ completed: Math.min(completed + diff, 100) });
        }
    };
    render() {
        const { classes, auth } = this.props;
            if(auth.auth.isLogin) {
                return (
                    <div>
                        <MenuAppBar/>
                        <Content/>
                        <Footer/>
                    </div>
                );
            } else {
                return (
                    <div className={classes.root}>
                        <LinearProgress variant="determinate" value={this.state.completed} />
                    </div>
                );
            }
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(
    (state) => ({
        auth: state.auth.initialState,
    })
)(withStyles(styles)(Home))