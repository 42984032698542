import React, {Component} from 'react';
import {Grid, withStyles} from '@material-ui/core';
import _ from 'lodash';

const styles = theme => ({
    root: {
        paddingTop: 5,
        padding: 3,
        ...theme.typography.body2,
    }
});

class TimeFrame extends Component {

    addZero = (value) => {
        return (value <10) ? '0' + Number(value) : value;
    };

    formatHour = (hour) => {
        let result = '';
        if(hour) {
            let fomart_hour = _.split(hour, ':');
            result = (this.addZero(fomart_hour[0]) + ':' + this.addZero(fomart_hour[1]));
        }
        return result;
    };

    render() {
        const {classes, hourStart = '0:0', hourEnd = '0:0', classTimeFrame} = this.props;
        return (
            <Grid item xs={12} className={classes.root + ' ' + classTimeFrame}>
                {this.formatHour(hourStart)}~{this.formatHour(hourEnd)}
            </Grid>
        );
    }
}

export default withStyles(styles)(TimeFrame);