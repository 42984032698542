import React, {Component} from 'react'
import {Grid, withStyles, Avatar} from '@material-ui/core'
import {connect} from "react-redux";

const styles = theme => ({
    avatar: {
        maxHeight: 45,
        maxWidth: 45,
        [theme.breakpoints.down("md")]: {
            maxHeight: 35,
            maxWidth: 35,
        },
    },
    title: {
        ...theme.typography.title,
        [theme.breakpoints.down("md")]: {
            marginLeft:15,
            fontSize:'0.875rem',
            fontWeight:'bold'
        },
    },
    content: {
        float:'right',
        fontSize:'1.3rem',
        margin:0,
        [theme.breakpoints.down("md")]: {
            fontSize: '0.95rem',
            textAlign: 'center',
            marginTop:-5,
        },
    },

    date: {
        ...theme.typography.h6,
        fontSize: '0.8rem',
        [theme.breakpoints.down("md")]: {
        marginLeft:15
    },
    }
})

class ContentTitle extends Component {

    render() {
        const {classes, src, title = '', date = '',data} = this.props;
        let backgroundColor = ''
        if(this.props.color[this.props.id]) {
            backgroundColor = this.props.color[this.props.id]
        }else if(data.tags.length===0 ){
            backgroundColor = 'grey'
        }else {
            backgroundColor = data.tags[0].color
        }
        return (
            <Grid container item xs={12}>
                <Grid item xs={1}>
                    <Avatar src={src} className={classes.avatar}/>
                </Grid>
                <Grid  item xs={7}>
                    <Grid item xs={12} className={classes.title}>{title}</Grid>
                    <Grid item xs={12} className={classes.date}>{date}</Grid>
                </Grid>
                <Grid item xs={4} style={{textAlign:'right',color:`${backgroundColor}`}}>
                    <p  className={classes.content} style={{}}>{this.props.content[this.props.id]}</p>
                </Grid>
            </Grid>
        );
    }
}

export default connect((state)=>({
    color :state.color,
    content :state.content,
})) (withStyles(styles)(ContentTitle));