import React, { Component } from 'react';
import { Button, Card, CardActions, CardContent, Popover, Typography } from "@material-ui/core";
import apiUrl from "../../../config/apiUrl";

import { LOGOUT } from '../../../constants/actionTypes';
import { connect } from "react-redux";

const mapStateToProps = function (state) {
    return {
        ...state,
    }
};
const mapDispatchToProps = dispatch => ({
    onClickLogout: () => dispatch({ type: LOGOUT }),
});

class AccountPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            loading: false,
            showAvatarUploader: false,
            image: null,
            avatarName: "",
            imageChanged: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.anchorEl !== state.anchorEl) {
            return {
                anchorEl: props.anchorEl,
            };
        }
        return null;
    }

    onSwitchAccount = () => {
        let url = apiUrl.SWITCH_ACCOUNT;
        window.location.assign(url);
    }
    myAccount = () => {
        let url = apiUrl.MY_ACCOUNT;
        window.location.assign(url);
    }

    render() {
        const { anchorEl, loading } = this.state;
        const { last_name, first_name } = this.props;
        const { onClose } = this.props;
        return (
            <div className="AccountPopup">
                <Popover
                    id={"AccountPopup"}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Card>
                        <CardContent style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <Typography variant="title" gutterBottom>
                                    {`${last_name || ''} ${first_name || ''}`}
                                </Typography>
                            </div>
                            <div>
                                <Button
                                    className="btnPrimary noUnderline"
                                    disabled={loading}
                                    onClick={this.myAccount}>
                                    アカウント
                                </Button>
                            </div>
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant={"outlined"} disabled={loading} className={"noUnderline"}
                                    onClick={this.onSwitchAccount}>アカウントの切り替え</Button>
                            <Button variant={"outlined"}
                                    disabled={loading}
                                    onClick={this.props.onClickLogout}
                            >ログアウト</Button>
                        </CardActions>

                    </Card>

                </Popover>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPopup);
