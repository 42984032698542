import {CardMedia, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {FiberManualRecord} from "@material-ui/icons";

const styles = {
    footer: {
        paddingTop: 8,
        fontSize: '0.9375rem',
    },
    footerRight:{
        textAlign: 'right',
        marginTop: -22,
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        objectFit: 'cover',
        maxHeight: 325,
    },
    TextSub:{
        fontSize: '0.75rem',
        textAlign: 'right'
    }
};

class KomaLeft extends Component {
    render() {
        const {
            classes,
            title,
            img,
            NumberOn = 0,
            OnTo = 0,
            Coma = 0,
            Minute = 0
        } = this.props;
        return (
            <Grid container>
                <CardMedia
                    component="img"
                    alt={title}
                    className={classes.media}
                    image={!img ? process.env.PUBLIC_URL + '/img/Coma.png' : img}
                    title={title}
                />
                <Grid container item xs={12} className={classes.footer}>
                    <Grid item xs={12}>
                        {NumberOn} on {OnTo} セッション
                    </Grid>
                    <Grid container item xs={12} className={classes.footerRight} justify={'flex-end'}>
                        <FiberManualRecord style={{position: 'relative', bottom: 2, left: 4}} />
                        <span style={{position: "relative", right: 10, top: 4,}}>
                            <CardMedia
                                component="img"
                                image={process.env.PUBLIC_URL + '/img/1496.png'}
                                alt={title}
                                title={title}
                                className={classes.media}
                            />
                        </span>
                        {Coma}
                        <sub className={classes.TextSub}> コマ </sub>
                        {Minute}
                        <sub className={classes.TextSub}> 分 </sub>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(KomaLeft);