import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {Book} from "@material-ui/icons";
import Progress from "../Progress/Progress";
import Topic from "../Topic/Topic";

class ProgressApp extends Component {
    render() {
        return (
            <Grid item>
                <Grid container spacing={8}>
                    <Topic icon={<Book/>} title={'Myカルテ'}/>
                    <Progress
                        title={'予約済みのイベント'}
                        point={5}
                        maxPoint={12}
                    />
                    <Progress
                        title={'ユニゾンリスニング'}
                        point={10}
                        maxPoint={20}
                        keyNumber={1}
                        colorOfPoint={'#67626e'}
                        backgroundColorPoint={'#d6d6d6'}
                        timeProgress={100}
                    />
                    <Progress
                        title={'エネカラー'}
                        point={3}
                        keyNumber={2}
                        colorOfPoint={'#f5cf23'}
                        backgroundColorPoint={'#fbf9ea'}
                        timeProgress={100}
                    />
                    <Progress
                        title={'Inner Tours'}
                        point={18}
                        maxPoint={20}
                        keyNumber={3}
                        colorOfPoint={'#29abe2'}
                        timeProgress={-9999}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default ProgressApp