import {GET_COLOR,UPDATE_COLOR} from "../constants/actionTypes";


const initialize = []

export default (state = initialize, action) => {

    switch (action.type) {

        case GET_COLOR:
           let arr = [...state]
            arr[action.id] = action.color
            //console.log('CLone color : ',arr)
            return arr
        case UPDATE_COLOR:
            console.log('update:',[...state])
            const result = action.color
            state.push(result)
            console.log('update:',[...state])
            return [...state]
        default:
            return state;
    }
}