import { store } from '../store'
import _ from 'lodash'
import axios from 'axios'
import { GET_TASK_LIST } from "../config/apiUrl";

export const getTask = () => {
    const insightmap = store.getState().insightMap.data
    let arr = []
    if(!_.isEmpty(insightmap)){
        insightmap.forEach(i=>{
            arr.push(i.uuid)
        })
    }
    axios.post(GET_TASK_LIST,{
        insightmap:arr
    }).then(res=>{
        store.dispatch({
            type:'SET_TASK',
            tasks: res.data
        })
    })
        .catch(e=>{
            console.log(e)
        })
}
